export const ActionType = {
    SHOW_LOADER: "SHOW_LOADER",
    SHOW_SNACKBAR: "SHOW_SNACKBAR",
    GET_QUESTION_BY_CHAPTER_ID: "GET_QUESTION_BY_CHAPTER_ID",
    RESET_GET_QUESTION_BY_CHAPTER_ID: "RESET_GET_QUESTION_BY_CHAPTER_ID",
    TEST_SERIES_QUESTION_NUMBER_DATA: "TEST_SERIES_QUESTION_NUMBER_DATA",
    RESET_TEST_SERIES_QUESTION_NUMBER_DATA: "RESET_TEST_SERIES_QUESTION_NUMBER_DATA",
    TEST_SERIES_QUESTION_DATA: "TEST_SERIES_QUESTION_DATA",
    RESET_TEST_SERIES_QUESTION_DATA: "RESET_TEST_SERIES_QUESTION_DATA",
    UPDATE_SELECTED_ANSWER: "UPDATE_SELECTED_ANSWER",
    UPDATE_QUESTION_STATUS: "UPDATE_QUESTION_STATUS",
    UPLOAD_TEST_RESPONSE: "UPLOAD_TEST_RESPONSE",
    RESET_UPLOAD_TEST_RESPONSE: "RESET_UPLOAD_TEST_RESPONSE",
    TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID: "TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID",
    RESET_TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID: "RESET_TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID",
}