export const convertMinuteToHourAndMinute = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const hoursString = hours === 1 ? '1 Hour' : hours > 1 ? `${hours} Hours` : '';
  const minutesString = remainingMinutes === 1 ? '1 Min' : remainingMinutes > 1 ? `${remainingMinutes} Mins` : '';

  if (hoursString && minutesString) {
    return `${hoursString} ${minutesString}`;
  } else if (hoursString) {
    return hoursString;
  } else {
    return minutesString;
  }
}

export function secondsToHHMMSS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsFinal = remainingSeconds % 60;

  const hoursString = String(hours).padStart(2, "0");
  const minutesString = String(minutes).padStart(2, "0");
  const secondsString = String(remainingSecondsFinal).padStart(2, "0");

  return `${hoursString}:${minutesString}:${secondsString}`;
}

export function isIOS() {
  return /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function isIPadOS() {
  return (
      /iPad/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  ) && !window.MSStream;
}