import React from 'react'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function MultiCarousel() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            <div>

                <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition=""
                    transitionDuration={500}
                    containerClass="carousel-container "
                    dotListClass="custom-dot-list-style mt-10 my-4  -bottom-10"
                    itemClass="carousel-item-padding-40-px flex  ml-auto mr-auto left-0 right-0 self-center justify-center"
                >
                    <div><img src={require('../assets/img/img1.png')} alt="" className='h-[350px] md:h-[550px]' /></div>
                    <div><img src={require('../assets/img/img2.png')} alt="" className='h-[350px] md:h-[550px]' /></div>
                    <div><img src={require('../assets/img/img3.png')} alt="" className='h-[350px] md:h-[550px]' /></div>
                    <div><img src={require('../assets/img/img4.png')} alt="" className='h-[350px] md:h-[550px]' /></div>
                    <div><img src={require('../assets/img/img5.png')} alt="" className='h-[350px] md:h-[550px]' /></div>
                    <div><img src={require('../assets/img/img6.png')} alt="" className='h-[350px] md:h-[550px]' /></div>

                </Carousel>

            </div>
        </>
    )
}

export default MultiCarousel
