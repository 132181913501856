import { Constant } from "../../constant/constant";
import { makeNetworkCall } from "../../network/BaseAxios";
import { NetworkConstant } from "../../network/NetworkConstant";
import { ActionType } from "./ActionType";

const makeNetworkCallForGetQuestionByChapterById =
  (type, endPoint, chapterId, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const updatedEndPoint = `${endPoint}?chapterId=${chapterId}`;
      const { data, status } = await makeNetworkCall(
        type,
        updatedEndPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.GET_QUESTION_BY_CHAPTER_ID,
          payload: {
            getQuestionsByChapterId: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data?.responseData?.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

const createTestSeriesQuestionNumberData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.TEST_SERIES_QUESTION_NUMBER_DATA,
      payload: {
        testSeriesQuestionNumberData: data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: "Something went wrong",
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const createTestSeriesQuestionData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.TEST_SERIES_QUESTION_DATA,
      payload: {
        testSeriesQuestionData: data,
      },
    });
  } catch (error) {
    dispatch({
      type: ActionType.SHOW_SNACKBAR,
      payload: {
        showSnackBar: true,
        snackBarMessage: "Something went wrong",
        snackBarSeverity: Constant.SEVERITY_ERROR,
        apiError: true,
      },
    });
  }
};

const updateQuestionData = (index, selectedOptionId) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.UPDATE_SELECTED_ANSWER,
      payload: {
        index: index,
        selectedOptionId: selectedOptionId
      },
    });
  } catch (error) {
    // Do Nothing
  }
};

const updateQuestionNumberData = (index, questionStatus) => async (dispatch) => {
  try {
    dispatch({
      type: ActionType.UPDATE_QUESTION_STATUS,
      payload: {
        index: index,
        questionStatus: questionStatus
      },
    });
  } catch (error) {
    // Do Nothing
  }
};

const makeNetworkCallUploadTestResponse =
  (type, endPoint, body, header) => async (dispatch) => {
    dispatch({
      type: ActionType.SHOW_LOADER,
      payload: { showLoader: true },
    });
    try {
      const { data, status } = await makeNetworkCall(
        type,
        endPoint,
        body,
        header
      );
      dispatch({
        type: ActionType.SHOW_LOADER,
        payload: { showLoader: false },
      });
      if (status === 200) {
        dispatch({
          type: ActionType.UPLOAD_TEST_RESPONSE,
          payload: {
            uploadTestResponseData: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data?.responseData?.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

  const makeNetworkCallGetTestResponseByUserIdAndChapterId =
  (type, updatedEndPoint, body, header) => async (dispatch) => {
    var endPoint = `${NetworkConstant.getTestResponseByUserIdAndChapterId}?${updatedEndPoint}`
    try {
      const { data, status } = await makeNetworkCall(
        type,
        endPoint,
        body,
        header
      );
      if (status === 200) {
        dispatch({
          type: ActionType.TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID,
          payload: {
            getTestResponseByUserIdAndChapterId: {
              responseData: data.responseData,
              statusCode: 200,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: true,
            snackBarMessage: data?.responseData?.message,
            snackBarSeverity: Constant.SEVERITY_ERROR,
            apiError: true,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_SNACKBAR,
        payload: {
          showSnackBar: true,
          snackBarMessage: error?.response?.data?.responseData?.message,
          snackBarSeverity: Constant.SEVERITY_ERROR,
          apiError: true,
        },
      });
    }
  };

export {
  makeNetworkCallForGetQuestionByChapterById,
  createTestSeriesQuestionNumberData,
  createTestSeriesQuestionData,
  updateQuestionData,
  updateQuestionNumberData,
  makeNetworkCallUploadTestResponse,
  makeNetworkCallGetTestResponseByUserIdAndChapterId
};
