export const AttemptConstant = {
  PRESTINE: "prestined",
  ANSWERED: "answered",
  ANSWERED_REVIEW: "marked_review",
  NOT_ANSWER_REVIEW: "not_answered_marked_review",
  NOT_VISITED: "not_visited",
  NOT_ANSWERED: "not_answered",
  MARKED_AND_ANSWERED: "marked_answered",
  CORRECT: "CORRECT",
  INCORRECT: "INCORRECT",
  UNATTEMPTED: "UNATTEMPTED",
};

export const SemiCircle = ({
  questionId,
  backgroundColor,
  callback,
  isSelected = true,
}) => {
  return (
    <div>
      <div
        onClick={() => callback(questionId)}
        style={{
          height: 50,
          width: 50,
          padding: 12,
          margin: 10,
          cursor: "pointer",
          borderRadius: 12,
          textAlign: "center",
          alignContent: "center",
          backgroundColor: isSelected ? backgroundColor : "white",
          color: isSelected ? "white" : backgroundColor,
        }}
      >
        {questionId}
      </div>
    </div>
  );
};

export const SemiCircleInverted = ({
  questionId,
  backgroundColor = "indigo",
  callback,
  isSelected = true,
}) => {
  return (
    <div>
      <div
        onClick={() => callback(questionId)}
        style={{
          height: 50,
          width: 50,
          padding: 12,
          margin: 10,
          cursor: "pointer",
          borderRadius: 12,
          textAlign: "center",
          alignContent: "center",
          backgroundColor: "indigo",
          color: "white",
        }}
      >
        <p className="self-center">{questionId}</p>
      </div>
    </div>
  );
};

export const SemiCircleInverted2 = ({
  questionId,
  backgroundColor = "indigo",
  callback,
  isSelected = true,
}) => {
  return (
    <div>
      <div
        onClick={() => callback(questionId)}
        style={{
          height: 50,
          width: 50,
          padding: 12,
          margin: 10,
          cursor: "pointer",
          borderRadius: 12,
          textAlign: "center",
          alignContent: "center",
          backgroundColor: "blue",
          color: "white",
        }}
      >
        <p className="self-center">{questionId}</p>
      </div>
    </div>
  );
};

export const Circle = ({
  questionId,
  backgroundColor,
  callback,
  isSelected = true,
}) => {
  return (
    <div
      onClick={() => callback(questionId)}
      style={{
        height: 50,
        width: 50,
        padding: 12,
        margin: 10,
        cursor: "pointer",
        textAlign: "center",
        alignContent: "center",
        borderRadius: 12,
        backgroundColor: "green",
        color: "white",
      }}
    >
      <p className="self-center">{questionId}</p>
    </div>
  );
};

export const HorizontalPill = ({
  questionId,
  backgroundColor,
  callback,
  isSelected = false,
}) => {
  return (
    <div
      onClick={() => callback(questionId)}
      style={{
        height: "50px",
        width: "50px",
        cursor: "pointer",
        padding: 12,
        margin: 10,
        color: isSelected ? backgroundColor : "black",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        borderRadius: 12,
        border: `1px solid ${isSelected ? backgroundColor : "white"}`,
        backgroundColor: isSelected ? backgroundColor : "white",
      }}
      className=""
    >
      {questionId}
    </div>
  );
};
