import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppConstant } from "../.environments";
import { AttemptConstant } from "../ExamModule/AttemptType";

const initialState = {
  questionsData: {},
  currentQuestion: 0,
  loading: false,
  error: "",
};

const fetchQuestionForCourse = createAsyncThunk(
  "question/fetchCourseQuestion",
  ({ chapterId }) => {
    return axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/chapter/getchapterbyid?chapterId=${chapterId}`
      )
      .then((res) => {
        const copy = { ...res?.data?.responseData };
        const questions = manageQuestion(
          res?.data?.responseData?.questionRequest
        );
        copy["questionRequest"] = questions;

        return copy;
      });
  }
);

const manageQuestion = (questions) => {
  const _questions = [...questions];

  return _questions.map((question) => {
    const correctAnswer = question?.optionRequest?.filter(
      (item) => item.correct === true
    );

    return {
      ...question,
      questionState: AttemptConstant.PRESTINE,
      correctAnswer: correctAnswer?.[0],
      finalQuestionAttemptState: AttemptConstant.UNATTEMPTED,
      selectedOption: "",
    };
  });
};

const questionsDetail = createSlice({
  name: "questionReducer",
  initialState,
  reducers: {
    updateCurrentQuestion(state, action) {
      state.currentQuestion = action.payload;
    },
    updateQuestionAttemptState(state, action) {
      state.questionsData.questionRequest[state.currentQuestion].questionState =
        action.payload.state;
      state.questionsData.questionRequest[
        state.currentQuestion
      ].selectedOption = action.payload.selectedOption;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionForCourse.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchQuestionForCourse.fulfilled, (state, action) => {
      state.loading = false;
      state.questionsData = action.payload;
      state.error = "";
    });
    builder.addCase(fetchQuestionForCourse.rejected, (state, action) => {
      state.loading = false;
      state.questionsData = [];
      state.error = action.error.message;
    });
  },
});

export default questionsDetail.reducer;
export const { updateCurrentQuestion, updateQuestionAttemptState } =
  questionsDetail.actions;

export { fetchQuestionForCourse };
