import React from "react";

import reproneetLogo from "../assets/images/Reproneet Learning.svg";

import { BsFacebook, BsYoutube, BsInstagram, BsTelegram } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="md:flex  md:justify-center bg-gray-100 md:items-start ">
        <div className=" md:me-10  md:flex md:items-center ">
          <div className="">
            <img src={reproneetLogo} alt="" className="h-[170px]" />
          </div>
          <div className="text-center md:text-left ">
            <p className="font-bold text-3xl md:ps-5 md:pt-5">
              Follow <span className="text-red-500">Us</span>
            </p>

            <div className="flex items-center justify-center  my-5 md:flex md:justify-start md:items-start ">
              <a
                href="https://www.facebook.com/groups/338257620576574/?ref=share"
                className="mx-5 text-blue-700 text-3xl"
              >
                <BsFacebook />
              </a>
              <a
                href="https://www.youtube.com/@monalisasingh3598"
                className="mx-5  text-red-600 text-3xl"
              >
                <BsYoutube />
              </a>
              <a
                href="https://www.youtube.com/@monalisasingh3598"
                className="mx-5  text-blue-400 text-3xl"
              >
                <BsTelegram />
              </a>
              <a
                href="https://www.instagram.com/reproneet/?igshid=YmMyMTA2M2Y%3D"
                className="mx-5  text-pink-500 text-3xl"
              >
                <BsInstagram />
              </a>
            </div>

            <div className="">
              <ul className="flex flex-row justify-center items-start ">
                <ul className="border-r-2 border-black">
                  <li className=" my-1 py-2 px-3 md:px-5 md:mx-0  ">
                    <Link to="/terms" className=" px-0 mx-0">
                      Terms and Condition
                    </Link>
                  </li>
                  <li className=" my-1 py-2 px-3 md:px-5 md:mx-0   ">
                    <Link to="/privacy-policy" className="mx-0">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
                <ul className="">
                  <li className=" my-1 py-2 px-3 md:px-5 md:mx-0  ">
                    <Link to="/refund-policy" className="mx-0">
                      Refund Policy
                    </Link>
                  </li>
                  <li className=" my-1 py-2 px-3 md:px-5 md:mx-0  ">
                    <Link to="/shipping" className="mx-0">
                      Shipping & Delivery
                    </Link>
                  </li>
                  <li className=" my-1 py-2 px-3 md:px-5 md:mx-0  ">
                    <Link to="/contactus" className="mx-0">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>

        <div className=" md:mx-10 md:w-[500px]  px-5">
          <p className="font-bold text-3xl text-center my-5 md:text-left">
            About <span className="text-red-500">Reproneet</span>
          </p>

          <p className="text-justify text-gray-500">
            Elaborate explanatory video lectures, interesting slides, quick
            revision notes, multitude of MCQs, recalls, discussion groups of
            dedicated candidates, . & One mentor to teach everything and to
            solve all your doubts. Happy learning!
          </p>
        </div>

        <div className="">
          <p className="font-bold text-3xl text-center my-5 md:text-left">
            Contact <span className="text-red-500">Us</span>
          </p>

          <div className="flex items-center text-lg justify-center pb-5">
            <MdEmail className="text-3xl text-red-500 mx-2" />{" "}
            reproneet@gmail.com
          </div>
        </div>
      </div>

      <div className="  py-5 bottom-0 text-center flex bg-gray-100 px-0 right-0 left-0 justify-center ">
        <p className="text-sm md:text-lg px-0 mx-0">
          Copyright &copy; 2023 Reproneet Learning | Powered by{" "}
          <Link
            to="https://www.phynlabz.com"
            className="text-blue-800 mx-0 hover:text-purple-600"
          >
            Phynlabz Tech
          </Link>
        </p>
      </div>
    </>
  );
}

export default Footer;
