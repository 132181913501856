import { Toolbar } from "../toolbar/toolbar";

const NotificationScreen = () => {
  return (
    <div className="w-full h-screen flex flex-col ">
      <Toolbar />

      <div className="flex justify-center w-3/4 bg-red-50 h-[200px] self-center p-10">
        <p className="font-bold text-gray-800 text-2xl">
          Important Notifications
        </p>
      </div>
    </div>
  );
};

export default NotificationScreen;
