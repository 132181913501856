import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const TestSeriesModal = ({
  title,
  description,
  open,
  handleClose,
  buttonCallback,
}) => {
  const handleModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: '16px'
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => {
            buttonCallback();
            handleClose();
          }}
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
};

export default TestSeriesModal;
