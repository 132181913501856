import { AnswerView } from "./AnswerView";
import ExamSideBar from "./ExamSideBar";
import { Toolbar } from "../toolbar/toolbar";
import Watermark from "@uiw/react-watermark";
import ExamSupportToolbar from "./ExamSupportToolbar";
import { ExamHeaderCountDownSubmitToolbar } from "./ExamHeaderCountDownSubmitToolbar";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const StartExam = () => {
  let location = useLocation();

  const courseData = {
    chapterId: location?.state?.chapterId,
    userId: location?.state?.userId,
    sectionId: location?.state?.sectionId,
    courseId: location?.state?.courseId,
  };

  return (
    <div className="w-full flex p-1 flex-col self-center ">
      <Toolbar />
      <Watermark
        content={["Reproneet Learning ", "Dr. Monalisa Singh"]}
        className="bg-white w-full px-0 lg:px-[4rem] lg:h-[540px]"
      >
        <ExamSupportToolbar />
        <ExamHeaderCountDownSubmitToolbar courseData={courseData} />
        <div className="flex  lg:h-[540px] justify-center self-center flex-col-reverse lg:flex-row max-md: mt-0 ">
          <div className="flex flex-col w-full lg:flex-row  ">
            {/* <QuestionView /> */}
            <AnswerView />
          </div>
          <ExamSideBar />
        </div>
      </Watermark>
    </div>
  );
};

export default StartExam;
