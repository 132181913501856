import { useNavigate, useParams } from "react-router-dom";
import { Toolbar } from "../toolbar/toolbar";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourses } from "../redux/courseSlice";

const CourseComponent = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [filtered, setFiltered] = useState({});
  const { courses, loading } = useSelector((state) => state.courseSlice);

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    const filteredCourse = courses.filter(
      (course) => course.courseId === courseId
    );
    console.log(filteredCourse);
    setFiltered(filteredCourse?.[0]);
  }, [courses]);

  return (
    <div>
      <Toolbar />

      <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
        <p className="text-white text-5xl font-bold"> {filtered?.title}</p>
      </div>

      <div className="flex flex-col   items-center   mt-10 px-4 mb-40">
        <img src={filtered?.thumbnail} className="flex my-5 self-center" />

        {/* <p className="font-bold text-3xl mb-10 text-gray-700">WHY CHOOSE US</p> */}
        <button
          className="self-center text-2xl bg-red-500 px-6 py-2 text-white font-bold rounded-lg mb-20"
          onClick={() => {
            navigate("/courseInfo", {
              state: {
                title: filtered?.title,
                description: filtered?.description,
                courseId: filtered?.courseId,
                thumbnail: filtered?.thumbnail,
                totalDuration: filtered?.totalDuration,
                courseDurationPriceResponse:
                  filtered?.courseDurationPriceResponse,
              },
            });
          }}
        >
          Click here to buy Now
        </button>
        <button
          className="self-center text-2xl bg-blue-500 px-6 py-2 text-white font-bold rounded-lg mb-20"
          onClick={() => {
            const path = filtered?.filePath?.substring(
              filtered?.filePath?.lastIndexOf("/") + 1
            );
            window.open(
              `https://www.reproneetlearning.com/course-viewer/${path}/web`,
              "_blank"
            );
          }}
        >
          View Syllabus
        </button>

        <div>➼ The perfect comprehensive learning approach Professional</div>
        <div>
          ➼ Faculty Follows interactive classroom learning model Extensive study
        </div>
        <div>
          ➼ Material Test and assessment Online preparations Regular Updates for
          parents
        </div>
      </div>
    </div>
  );
};

export default CourseComponent;
