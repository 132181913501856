import React from 'react'

import { Toolbar } from "../toolbar/toolbar";
import ReactPlayer from 'react-player';
import { Accordion, AccordionItem } from "@szhsin/react-accordion";


import { AiOutlineFileText, AiOutlineCloudUpload, AiOutlinePlus } from 'react-icons/ai'
import { FaBookMedical } from 'react-icons/fa'
import Footer from '../Footer/Footer';




function AboutUs() {

    const Careers = [
        {
            candidateName: "Dr. Sunita Naik",
            rank: 5,
            course: "NEET SS 2021",
            url: "https://youtu.be/_IKKYxt0-rE?si=BRsxrWC0viikRdA2",
        },
    ]


    return (

        <div>

            <Toolbar />

            <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
                <p className="text-white text-5xl font-bold"> About Us</p>
            </div>


            {/* ............................. */}

            <div className='flex items-center justify-center '>

                <div className="w-full  flex flex-col md:flex-row md:items-center md:justify-center md  mx-0 px-0 md:my-10 md:self-center lg:w-[70%]">

                    <div className="px-5 my-5 lg:w-[50%] ">

                        <div className=" flex px-3 ">
                            <div className="flex   flex-col  text-4xl  ">
                                <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
                                    We Provide   <span className="text-red-600 hover:text-black "> Coaching For </span>
                                </p>
                                <p className="text-base font">
                                    What are Super speciality field options after PG in Obs & Gyn.
                                </p>
                                <p className='text-base my-5'>
                                    Elaborate explanatory video lectures, interesting slides, quick revision notes, multitude of MCQs, recalls, discussion groups of dedicated candidates, . & One mentor to teach everything and to solve all your doubts. Happy learning!
                                </p>
                            </div>
                        </div>

                        <div className="flex  my-10 ">
                            <div className="flex text-red-600   w-auto items-center ">
                                <div className=" border-2 border-red-500   rounded-full h-[5rem] w-[5rem] flex justify-center items-center mr-4  hover:bg-red-500 hover:text-white ">
                                    <AiOutlineFileText className="border-5 text-5xl " />
                                </div>
                                <div className='flex flex-col text-black '>
                                    <p className="font-bold text-xl ">Super Speciality Entrance Exams</p><br />
                                    <p>NEET SS, INISSET, FET in OBG</p>
                                </div>
                            </div>
                        </div>


                        <div className="flex  my-10 ">
                            <div className="flex text-red-600 w-auto items-center ">
                                <div className=" border-2 border-red-500   rounded-full h-[5rem] w-[5rem] flex justify-center items-center mr-4  hover:bg-red-500 hover:text-white  ">
                                    <AiOutlineCloudUpload className="border-5 text-5xl " />
                                </div>
                                <div className='flex flex-col text-black '>
                                    <p className="font-bold text-xl ">PDCET</p><br />
                                    <p>FOR DGO pass outs</p>
                                </div>
                            </div>
                        </div>


                        <div className="flex  my-10 ">
                            <div className="flex text-red-600  w-auto items-center ">
                                <div className=" border-2 border-red-500   rounded-full h-[5rem] w-[5rem] flex justify-center items-center mr-4  hover:bg-red-500 hover:text-white  ">
                                    <FaBookMedical className="border-5 text-5xl " />
                                </div>
                                <div className='flex flex-col text-black '>
                                    <p className="font-bold text-xl ">University Exams</p><br />
                                    <p>MD/MS/DNB/DGO</p>
                                </div>
                            </div>
                        </div>





                    </div>

                    <div className="flex flex-wrap  self-center justify-center mt-50 px-0 mx-0 lg:w-[50%] ">
                        {Careers.map((item) => {
                            return (
                                <div className="w-[320px] h-[220px]  shadow-sm drop-shadow-lg rounded-md  my-10 ">
                                    <ReactPlayer url={item.url} width={320} height={250} />

                                </div>
                            );
                        })}
                    </div>

                </div>
            </div>

            {/* ............................. */}


            {/* ............................. */}


            <div className='flex items-center justify-center md:flex-col px-5 md:px-0 my-10 md:my-0 md:text-lg '>

                <div className=' lg:w-[70%]'>
                    <div className="flex  my-3">
                        <div className=" flex justify-center items-center mx-2">
                            <AiOutlinePlus className="text-red-500" />
                        </div>
                        <p className="font-base">
                            Recorded video lectures on entire syllabus, that are up to date
                            and are updated every week
                        </p>
                    </div>

                    <div className="flex  my-3">
                        <div className=" flex justify-center items-center mx-2">
                            <AiOutlinePlus className="text-red-500" />
                        </div>
                        <p className="font-base">Notes to revise the lectures</p>
                    </div>
                    <div className="flex  my-3">
                        <div className=" flex justify-center items-center mx-2">
                            <AiOutlinePlus className="text-red-500" />
                        </div>
                        <p className="font-base">
                            4000+ MCQs and more to be added every month
                        </p>
                    </div>
                    <div className="flex  my-3">
                        <div className=" flex justify-center items-center mx-2">
                            <AiOutlinePlus className="text-red-500" />
                        </div>
                        <p className="font-base">
                            Discussion group on telegram for candidates to post doubts and get
                            clarifications from the mentor
                        </p>
                    </div>

                    <div className="flex  my-3">
                        <div className=" flex justify-center items-center mx-2">
                            <AiOutlinePlus className="text-red-500" />
                        </div>
                        <p className="font-base">
                            Only one dedicated mentor, who is directly in touch with all
                            candidates.
                        </p>
                    </div>
                </div>

            </div>


            {/* ............................. */}


            {/* ............................. */}


            <div className='flex items-center justify-center md:flex-col bg-gray-100 ' >
                <div className=' lg:w-[70%] px-5 py-5 md:px-3'>
                    <div className='border-l-4 border-red-400 text-2xl md:text-2xl text-black '>
                        <p className="ms-3">
                            THE MENTOR <br />
                            DR. MONALISA SINGH <br />
                            MBBS (GRANT MEDICAL COLLEGE, MUMBAI) <br />
                            MS (LTMMC AND LTMGH, MUMBAI) <br />
                            FNB (SIR GANGARAM HOSPITAL, NEW DELHI) <br />
                            CURRENTLY PRACTICING AS A SENIOR CONSULTANT AT A CORPORATE HOSPITAL IN HER HOMETOWN, PATNA
                        </p>
                    </div>

                    <div className='my-10 text-gray-500 text-justify md:w-[85%] md:ms-10'>
                        <p>
                            Dr. Monalisa Singh is an avid reader and has believed in making the most elaborate notes for later revisions. She has always had a passion for teaching. She is loved by her students for making the subject easy to understand and interesting. Students enjoy listening to her lectures for hours together. They find that reading many chapters in the same day could be tasking and it’s difficult to maintain the same level of concentration, throughout the day, with books. Whereas, covering the same topics through madams video lectures are quite easy. Her way of talking is comparatively much more engaging than going through big chapters in big books. This makes it easier to cover the entire syllabus in a short duration of time, for a headstrong hardworking candidate.
                        </p>

                        <p className='my-10'>
                            Dr. Monalisa Singh has a busy clinical practice at a corporate hospital, where she practices high risk obstetrics, gynecology, infertility, gynecologic endoscopic surgeries. She believes that her involvement with teaching has improved her practice and has made her more confident with patients. She believes that being attached with Reproneet Learning will have the same effect on students.
                        </p>

                        <p className=''>
                            Our motto at Reproneet Learning is to prepare candidates not only for exams but also for actual clinical practice, which is more difficult of the two!
                        </p>
                    </div>

                </div>
            </div>


            {/* ............................. */}

            {/* Frequently Asked Questions code start */}

            <div className=" my-10  flex md:items-center justify-center flex-col">
                <div className="mb-10 mx-2 lg:w-[70%] ">
                    <p className="text-4xl md:text-5xl text-center font-bold">
                        Frequently Asked
                        <span className="text-red-500">Questions</span>
                    </p>
                </div>

                <div className="flex flex-col lg:flex-row justify-center  lg:w-[70%] ">
                    <div className="">
                        <img
                            src={require("../assets/img/Student stress-pana.png")}
                            className="w-full md:w-[55rem] "
                            alt=""
                        />
                    </div>

                    <div className="mx-5 my-10 lg:w-[30%] text-left flex items-start">
                        <Accordion>
                            <AccordionItem
                                header="How Do I Contact The Mentor At Reproneet"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    There are many ways to reach Dr. Monalisa for your clinical
                                    doubts and to join latest discussions.
                                    <ul className="mt-6 text">
                                        <li className="text-left  mx-0 px-0">
                                            Join telegram group and post your question here: <br></br>
                                            <a
                                                href="tg://join?invite=VzIpNbt8a_4yZDk1"
                                                className=" mx-0 px-0"
                                            >
                                                tg://join?invite=VzIpNbt8a_4yZDk1
                                            </a>
                                        </li>

                                        <li>
                                            Join FB group to see MCQ of the day and to participate in
                                            discussions: <br></br>
                                            <a
                                                href="https://www.facebook.com/groups/338257620576574/?ref=share"
                                                className=" mx-0 px-0 "
                                            >
                                                https://www.facebook.com/<br></br>groups/338257620576574/?ref=share
                                            </a>
                                        </li>

                                        <li>
                                            Send an email at
                                            <a
                                                href="tg://join?invite=VzIpNbt8a_4yZDk1"
                                                className=" mx-0 px-0"
                                            >
                                                {" "}
                                                reproneet@gmail.com{" "}
                                            </a>
                                        </li>
                                    </ul>
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="How To Seek Help For Technical Issues With The Apps"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    Send a mail to support@testpress.in and CC to
                                    reproneet@gmail.com Most issues will be resolved within 48
                                    working hours of raising the complain.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="What Is Difference Between 1 Year Course & 9 Month Course  Of NEET SS & FET, INISSET"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3 text-left"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    The contents of all the NEET SS/FET/INISSET courses are same.
                                    The difference between 1 year course, 9 month course and crash
                                    courses is only in terms of duration of subscription validity.
                                    Link to attend live lectures will be provided to students of
                                    the one year course. The same lectures will be uploaded on the
                                    app and website for all registered students within 24 hours,
                                    during the period of their subscription validity. Thus no one
                                    misses anything!
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="What Is The Difference Between PDCET Course & NEET SS/FET Course"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    The PDCET course covers syllabus for post DGO entrance test
                                    for secondary DNB. It includes video lectures, notes and MCQs,
                                    similar to the NEET SS/FET/INISSET course , except a few
                                    topics in reproductive Medicine & gynecologic oncology which
                                    are not included.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="What Are The Foundation Courses"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    Foundation courses are meant to help you prepare for
                                    university exams at the end of your post graduation as well as
                                    for the super speciality entrance exams like NEET SS, FET and
                                    INISSET. The contents are essentially same as NEET
                                    SS/FET?INISSET course with additional solved DNB Theory papers
                                    of last 5 years
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="What Are Focused Reproductive Medicine & Focused Gynecologic Oncology Courses"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    Each of these course only contain lectures and notes on topics
                                    of the respective sub speciality. General OBG topics are not
                                    covered. MCQs are same as other courses.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="How To Download The Videos & Notes From The App/Website"
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3 text-left mx-0 px-0"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    All the contents are provided online only. The videos, notes
                                    or MCQs are not downloadable or printable. Candidates may read
                                    directly from the app/website or may make their own notes,
                                    depending on their preferred method of revising.
                                </p>
                            </AccordionItem>

                            <AccordionItem
                                header="What Are The Offers & How To Know About Them."
                                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3 text-left"
                            >
                                <p className="font-normal mt-5 border-t-2 pt-5">
                                    Offers are announced on FB and telegram groups.
                                </p>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>

            {/* Frequently Asked Questions code end */}

            <div>
                <Footer />
            </div>



        </div>

    )
}

export default AboutUs
