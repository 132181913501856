import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toolbar } from "../toolbar/toolbar";
import { AttemptConstant } from "./AttemptType";
import { convertMinuteToHourAndMinute } from "../Utils/AppUtils";

const TestSubmittedAnalyticsScreen = () => {
  const location = useLocation();
  const [correct, setCorrect] = useState("");
  const [inCorrect, setInCorrect] = useState("");
  const [percent, setPercent] = useState("");

  useEffect(() => {
    var correctAnswers = 0;
    var inCorrectAnswers = 0;
    for (
      var i = 0;
      i < location?.state?.testSubmittedData[0]?.jsonRequest?.length;
      i++
    ) {
      if (
        location?.state?.testSubmittedData[0]?.jsonRequest[i]?.state ===
        AttemptConstant.CORRECT
      ) {
        correctAnswers = correctAnswers + 1;
      } else if (
        location?.state?.testSubmittedData[0]?.jsonRequest[i]?.state ===
        AttemptConstant.INCORRECT
      ) {
        inCorrectAnswers = inCorrectAnswers + 1;
      }
    }
    setCorrect(correctAnswers);
    setInCorrect(inCorrectAnswers);
    setPercent(
      (
        ((correctAnswers * 4 - inCorrectAnswers * 1) * 100) /
        (location?.state?.testSubmittedData[0]?.jsonRequest?.length * 4)
      ).toFixed(2)
    );
  }, []);

  const formatText = (text) => {
    const bulletChar = String.fromCharCode(8226);
    const formattedText = text?.split(bulletChar).join(`\n${bulletChar}`);
    return formattedText;
  };

  return (
    <>
      <div>
        <Toolbar />
        <h1 className="text-lg font-bold flex justify-center">
          Analytics & Solutions
        </h1>
        <div className="flex flex-col lg:flex-row lg:grid lg:grid-rows-2 lg:grid-flow-col lg:overflow-x-auto">
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/question-mark.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">
              Questions:{" "}
              {location?.state?.testSubmittedData[0]?.jsonRequest?.length}
            </p>
          </div>
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/total_marks.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">
              Total marks:{" "}
              {location?.state?.testSubmittedData[0]?.jsonRequest?.length * 4}
            </p>
          </div>
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/clock.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">
              Total time:
              {convertMinuteToHourAndMinute(
                location?.state?.testSubmittedData[0]?.jsonRequest?.length
              )}
            </p>
          </div>
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/score.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">
              Score : {correct * 4 - inCorrect * 1}
            </p>
          </div>
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/percentage.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">Percent: {percent}</p>
          </div>
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/approve-badge.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">Correct: {correct}</p>
          </div>
          <div className="p-5 m-10 rounded shadow flex items-center align-middle flex-wrap justify-center text-center">
            <img
              alt="question"
              src={require("../assets/illustrations/rejected.png")}
              width={60}
              height={60}
            />
            <p className="mx-3 text-lg font-medium">Incorrect: {inCorrect}</p>
          </div>
        </div>
        <div>
          {location?.state?.testSubmittedData?.[0]?.jsonRequest?.map(
            (value, key) => {
              return (
                <div className="p-5 my-5 mx-10 rounded shadow">
                  <p className="text-lg font-bold">
                    {key + 1}. {value.questionRequest[0]?.questionName}
                  </p>
                  {value.questionRequest[0]?.questionImageUrl?.map(
                    (questionImageUrl, questionImageIndex) => {
                      return (
                        <div
                          key={questionImageIndex}
                          className="w-500px h-500px object-contain"
                        >
                          <img alt="questionImage" src={questionImageUrl.url} />
                        </div>
                      );
                    }
                  )}
                  {value.questionRequest[0]?.optionRequest?.map(
                    (optionValue, optionKey) => {
                      return (
                        <div key={optionKey}>
                          <p
                            className={
                              optionValue?.correct
                                ? `text-green-500 font-medium`
                                : value.selectedAnswer ===
                                  optionValue?.optionName
                                ? `text-red-500 font-medium`
                                : `text-black-500 font-medium`
                            }
                          >
                            {(optionKey + 10).toString(36).toLowerCase()}.{" "}
                            {optionValue.optionName}
                          </p>
                          {optionValue?.optionImageUrl?.map(
                            (optionImageUrlValue, optionImageUrlIndex) => {
                              return (
                                <div
                                  key={optionImageUrlIndex}
                                  className="w-500px h-500px object-contain"
                                >
                                  <img
                                    alt="optionImage"
                                    src={optionImageUrlValue.url}
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }
                  )}
                  <p className="text-lg font-bold">Description</p>
                  {value.questionRequest[0]?.optionRequest?.map(
                    (optionValue, optionKey) => {
                      return (
                        <div key={optionKey}>
                          {optionValue.correct && (
                            <pre className="font-medium font-sans whitespace-pre-wrap">
                              {/* {optionValue.description} */}
                              {formatText(optionValue.description)}
                            </pre>
                          )}
                        </div>
                      );
                    }
                  )}
                  {value.questionRequest[0]?.descriptionImageUrl?.map(
                    (descriptionImageUrl, descriptionImageIndex) => {
                      return (
                        <div
                          key={descriptionImageIndex}
                          className="w-500px h-500px object-contain"
                        >
                          <img
                            alt="descriptionImage"
                            src={descriptionImageUrl.url}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default TestSubmittedAnalyticsScreen;
