import { useEffect } from "react";
import { Toolbar } from "../toolbar/toolbar";
import { fetchCourses } from "../redux/courseSlice";
import store from "../redux/store";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import person from "../assets/images/5245473-person-ai.svg";
import background from "../assets/images/bg-home-ai.svg";
import { socket } from "../App";
import { Link } from "react-router-dom";

import ReactPlayer from "react-player";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";

import {
  AiOutlineFileText,
  AiOutlinePlus,
  AiOutlineCloudUpload,
  AiFillStar,
} from "react-icons/ai";
import {
  FaBookMedical,
  FaNotesMedical,
  FaHandHoldingMedical,
} from "react-icons/fa";

import { BsArrowRight } from "react-icons/bs";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

// images

import s1 from "../assets/img/slider1.jpeg";
import s2 from "../assets/img/slider2.jpeg";
import s3 from "../assets/img/slider3.jpeg";
import s4 from "../assets/img/slider4.jpeg";
import s5 from "../assets/img/slider5.jpeg";
import MultiCarousel from "../MultiCarousel/MultiCarousel";
import Footer from "../Footer/Footer";

// images

export const HomeScreen = () => {
  const { userInfo, loading, error } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (userInfo.userId != null) {
      socket.emit("onlineUser", {
        payload: {
          platform: "web",
          device: "browser",
        },
        userId: userInfo.userId,
      });
    }
  }, []);

  const { courses } = useSelector((state) => state.courseSlice);

  const testimonial = [
    {
      candidateName: "Dr. Sunita Naik",
      rank: 5,
      course: "NEET SS 2021",
      url: "https://www.youtube.com/watch?v=-Dvnd9rT8_Y",
    },
    {
      candidateName: "Dr. Navin",
      rank: 6,
      course: "NEET SS 2021",
      url: "https://www.youtube.com/watch?v=O33E-IT--gc",
    },
    {
      candidateName: "Dr. Suvidya",
      rank: 1,
      course: "INISSET",
      url: "https://youtu.be/NWkdrdXwol0",
    },
  ];

  return (
    <div className="h-screen w-screen top-0 mt-[-5px] p-0 absolute flex flex-col  ">
      <Toolbar />

      {/* heading and image slider box code start */}

      <div className=" flex flex-col lg:flex-row  mt-20 max-sm:w-full max-sm:px-0 md:px-10 lg:mx-20">
        <div className="  w-3/5 max-sm:w-full max-sm:px-3 md:mx-2">
          <div className=" px-6 border-l-[6px] border-red-500   py-4 max-sm:ps-4 font-sans">
            <p className="font-bold text-black text-5xl max-sm:text-3xl max-sm:px-3 max-sm:mt-0 ">
              BEST ONLINE ACADEMIC INSTITUTE FOR LEARNING <br></br>{" "}
              <span className="text-red-500 hover:text-black">
                OBSTETRICS & GYNECOLOGY
              </span>
            </p>
          </div>

          <div className=" my-3 text-lg ">
            <p className="max-sm:text-base max-sm:ms-0 max-sm:px-10">
              A perfect forum to learn OBG, infertility and Gynecologic oncology
              and to prepare for NEET PG, FMGE, INICET, NEET SS, FET, INISSET
              and PDCET.
            </p>

            <div className="mt-10  sm:mx-0 sm:px-0  max-sm:w-full  max-sm:mt-5 flex items-center ">
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.foxlabz.edulabz"
                }
                className="bg-gradient-to-r from-orange-300 to-red-400 px-6 py-4 mx-5 text-white rounded-full hover:from-slate-200 hover:to-slate-200 hover:text-red-500 hover:ease-in duration-200 max-sm:mx-2  max-sm:px-2 max-sm:py-2 max-sm:text-xs  md:mx-0 md:me-3 flex justify-center md:items-center w-[190px] md:w-[250px] sm:mx-0"
              >
                Download for Android
                <BsArrowRight className=" ms-2" />
              </Link>
              <Link
                to={
                  "https://apps.apple.com/in/app/reproneet-learning/id6446031013"
                }
                className="bg-gradient-to-r from-slate-200 to-slate-200 px-6 py-4 mx-10 text-red-500   rounded-full hover:from-orange-300 hover:to-red-400 hover:text-white hover:ease-in duration-200 max-sm:mx-2  max-sm:px-3 max-sm:py-2 max-sm:text-xs  md:mx-0 md:ms-3  flex justify-center w-[180px] md:me-3 md:flex md:items-center md:w-[250px] md:justify-center sm:mx-0"
              >
                Download for iOS
                <BsArrowRight className=" ms-2" />
              </Link>
            </div>
          </div>
          <Link
            to={"/courses"}
            className="bg-gradient-to-r font-bold from-orange-500 to-blue-500 px-6 py-4 mx-5 text-white rounded-full   hover:ease-in duration-200 max-sm:mx-2  max-sm:px-2 max-sm:py-2 max-sm:text-xs  md:mx-0 md:me-3 flex justify-center md:items-center w-[190px] md:w-[250px] sm:mx-0"
          >
            VIEW COURSES
            <BsArrowRight className=" ms-2" />
          </Link>
        </div>

        <div className=" md:w-[500px] lg:w-[800px] md:mt-0 w-full px-4 lg:px-0 mt-10">
          <Carousel
            autoPlay={true}
            swipeable={true}
            stopOnHover={true}
            showThumbs={false}
            interval="2000"
            transitionTime="800"
            infiniteLoop={true}
          >
            <div>
              <img src={require("../assets/img/slider1.jpeg")} />
            </div>
            <div>
              <img src={s2} />
            </div>
            <div>
              <img src={s3} />
            </div>
            <div>
              <img src={s4} />
            </div>
            <div>
              <img src={s5} />
            </div>
          </Carousel>
        </div>
      </div>

      {/* heading and image slider box  code end */}

      {/* count box  code start */}

      <div className="count-box-outer mt-10 ">
        <div className="w-full bg-gray-700 opacity-90 drop-shadow-lg h-[45rem] py-4 lg:py-0 md:h-[15rem] flex lg:h-[10rem] lg:pt-20  text-center">
          <div className="w-full flex justify-between text-center lg:px-64 flex-col lg:flex-row items-center h-full">
            <div className="flex flex-col justify-center px-5 text-white ">
              <p className="text-6xl font-bold ">
                10<sup>+</sup>
              </p>
              <div className="h-[100px] mt-4">No. Of Course Options</div>
            </div>

            <div className="flex flex-col justify-center px-5 text-white ">
              <p className="text-6xl font-bold ">
                5<sup>+</sup>
              </p>
              <div className="h-[100px] mt-4">
                Years Of Experience In Teaching
              </div>
            </div>

            <div className="flex flex-col justify-center px-5 text-white ">
              <p className="text-6xl font-bold ">
                4,000<sup>+</sup>
              </p>
              <div className="h-[100px] mt-4">MCQs</div>

              <p className="mt-4"></p>
            </div>

            <div className="flex flex-col justify-center px-5 text-white ">
              <p className="text-6xl font-bold ">
                200<sup>+</sup>
              </p>
              <div className="h-[100px] mt-4">Hours Of Video Lectures</div>

              <p className="mt-4"></p>
            </div>
          </div>
        </div>
      </div>

      {/* count box  code end */}

      {/* study-app box  code start */}

      <div className="text-center items-center flex justify-center w-full my-16 ">
        <div className="w-full lg:w-1/2 px-4 md:px-0 flex justify-center  flex-col self-center  bg-slate-800 md:bg-white text-white md:text-gray-800  ">
          <p className=" text-3xl md:text-5xl my-10 font-bold text-white md:text-gray-800 ">
            A Time Saver <span className="text-red-500">Study App</span>
          </p>

          <p className="text-sm md:text-lg">
            Studying is easy with Reproneet- Know the syllabus of all exams,
            Understand concepts in video lectures, Ask questions, Get ready-made
            study material and all-inclusive notes, Solve MCQs,<br></br> Find
            the right mentor.
          </p>

          <span className="font-bold text-white md:text-gray-700 my-4 ">
            Nothing is difficult, if you find the right mentor!
          </span>
        </div>
      </div>

      <div className=" flex flex-col md:flex-row justify-center my-10 ">
        <div className="w-full md:w-1/3 flex flex-col   ">
          <div className=" flex px-3">
            <div className="w-[10px] md:w-[5px] bg-red-500 mr-4"></div>
            <div className="flex   flex-col  text-xl font-bold ">
              <p className="text-black">
                To join the Exclusive telegram group & to take NEET SS mock test
                click & Register here :
              </p>
              <p>
                <a href="" className=" text-red-500 font-bold px-0 mx-0">
                  https://reproneet.testpress.in/neet-ss-mock-test
                </a>
              </p>
            </div>
          </div>

          <div className="flex items-center my-3 mt-10">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              A telegram group of students preparing for NEET SS 2023 where
              candidates may post their questions
            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              Mentor will answer the questions with relevant explanation within
              48 hours of posting the question
            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              The group will be active till 1 month after NEET SS 2023
            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              One mock test based on current NEET SS pattern - To be held in
              first week of April
            </p>
          </div>
        </div>

        <div className="w-[2px] bg-red-500 mx-12"></div>

        <div className=" px-3 md:px-0 mt-4 md:mt-0 flex flex-col">
          <div className="flex items-center my-3">
            <div className="flex text-red-600  hover:text-black w-auto items-center">
              <div className="border border-2 border-red-500   rounded-full h-[3rem] w-[3rem] flex justify-center items-center mr-4 ">
                <AiOutlineFileText className="border-5 text-2xl text-red-600" />
              </div>
              <p className="font-bold">NEET SS / FET / INISSET Courses</p>
            </div>
          </div>

          <div className="flex items-center my-3">
            <div className="flex text-red-600  hover:text-black w-auto items-center">
              <div className="border border-2 border-red-500   rounded-full h-[3rem] w-[3rem] flex justify-center items-center mr-4 ">
                <AiOutlineCloudUpload className="border-5 text-2xl text-red-600" />
              </div>
              <p className="font-bold">PDCET Courses</p>
            </div>
          </div>

          <div className="flex  items-center my-3">
            <div className="flex text-red-600  hover:text-black w-auto items-center">
              <div className="border border-2 border-red-500   rounded-full h-[3rem] w-[3rem] flex justify-center items-center mr-4 ">
                <FaBookMedical className="border-5 text-2xl text-red-600" />
              </div>
              <p className="font-bold">Foundation Courses</p>
            </div>
          </div>

          <div className="flex  items-center my-3">
            <div className="flex text-red-600  hover:text-black w-auto items-center">
              <div className="border border-2 border-red-500   rounded-full h-[3rem] w-[3rem] flex justify-center items-center mr-4 ">
                <FaNotesMedical className="border-5 text-2xl text-red-600" />
              </div>
              <p className="font-bold">Focused Reproductive Medicine Course</p>
            </div>
          </div>

          <div className="flex items-center my-3">
            <div className="flex text-red-600  hover:text-black w-auto items-center">
              <div className="border border-2 border-red-500   rounded-full h-[3rem] w-[3rem] flex justify-center items-center mr-4 ">
                <FaHandHoldingMedical className="border-5 text-2xl text-red-600" />
              </div>
              <p className="font-bold">Focused Gynecologic Oncology Courses</p>
            </div>
          </div>
        </div>
      </div>

      {/* study-app box  code end */}

      {/* Why Reproneet box  code start */}

      <div className="flex flex-col-reverse md:flex-row justify-center bg-red-50 py-10">
        <div className="flex justify-start my-10  flex-col w-full md:w-1/3 px-3 md:px-0">
          <p className="text-3xl font-bold my-10 md:mt-0 mx-5  text-gray-700">
            Why Reproneet?
          </p>

          <div className="flex  my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              Recorded video lectures on entire syllabus, that are up to date
              and are updated every week
            </p>
          </div>

          <div className="flex  my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">Notes to revise the lectures</p>
          </div>
          <div className="flex  my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              4000+ MCQs and more to be added every month
            </p>
          </div>
          <div className="flex  my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              Discussion group on telegram for candidates to post doubts and get
              clarifications from the mentor
            </p>
          </div>

          <div className="flex  my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-orange-600" />
            </div>
            <p className="font-base">
              Only one dedicated mentor, who is directly in touch with all
              candidates.
            </p>
          </div>
        </div>

        <div className="w-[400px] flex justify-center  self-center">
          <img
            src={require("../assets/img/ss1.png")}
            className="h-[550px] "
            alt=""
          />
        </div>
      </div>

      {/* MultiCarousel box  code end */}

      <div>
        <div className="text-center items-center flex justify-center w-full my-16 ">
          <div className="w-full lg:w-1/2 px-4 md:px-0 flex justify-center  flex-col self-center   ">
            <p className=" text-3xl md:text-5xl my-10 font-bold   ">
              App <span className="text-red-500">Screenshots</span>
            </p>

            <p className="text-sm md:text-lg">
              Elaborate explanatory video lectures, interesting slides, quick
              revision notes, multitude of MCQs, recalls, discussion groups of
              dedicated candidates, . & One mentor to teach everything and to
              solve all your doubts. Happy learning!
            </p>
          </div>
        </div>

        <div className=" ">
          <MultiCarousel />
        </div>
      </div>

      {/* MultiCarousel box  code end */}

      {/* ............................. */}

      <div className="count-box-outer mt-10 hidden">
        <div className="w-full bg-gradient-to-r from-orange-300 to-red-400 opacity-90 drop-shadow-lg h-[32rem] py-4 lg:py-0 lg:h-[10rem] flex   text-center">
          <div className="w-full flex justify-between text-center text-gray-800 lg:px-64 flex-col lg:flex-row items-center h-full text-6xl font-bold italic ">
            <div className="flex flex-col justify-center px-5 ">
              <p className="mt-4 border-bottom-5 ">DNB-PDCET</p>
            </div>

            <div className="flex flex-col justify-center px-5 ">
              <p className="mt-4 border-bottom-5 ">NEET SS</p>
            </div>

            <div className="flex flex-col justify-center px-5 ">
              <p className="mt-4 border-bottom-5 ">FET</p>
            </div>

            <div className="flex flex-col justify-center px-5 ">
              <p className="mt-4 border-bottom-5 ">INISSET</p>
            </div>
          </div>
        </div>
      </div>

      {/* success stories code start */}

      <div className="flex flex-col md:flex-row justify-center py-10 md:mt-10">
        <div className="flex justify-center my-10  flex-col w-full md:w-1/3 px-3 md:px-0">
          <p className="text-4xl md:text-5xl font-bold my-5 md:my-10 md:mt-0 mx-5 text-center text-gray-700">
            Success <span className="text-red-600">Stories</span>
          </p>
          <p className="text-base text-center  md:my-10 md:mt-0 mx-5  text-gray-700">
            Read, What Our Students Say!
          </p>
        </div>

        <div className=" md:w-1/3 flex justify-center  m-5 bg-red-300">
          <Carousel
            autoPlay={true}
            swipeable={true}
            stopOnHover={true}
            showThumbs={false}
            interval="2000"
            transitionTime="1000"
          >
            <div className=" shadow-xl  p-5 border-4">
              <div>
                <p className="text-4xl text-yellow-400 flex items-center my-10">
                  <AiFillStar className=" " />
                  <AiFillStar className=" " />
                  <AiFillStar className=" " />
                  <AiFillStar className=" " />
                  <AiFillStar className=" " />
                </p>
                <p className="text-left text-gray-500">
                  {/* "“I am really overwhelmed by the suppprt and guidance given by dr. Monalisa ma’am. Initially, when I started preparation for the exam, there was no one to guide and no proper way to tackle the questions. But, ma’am taught us how to read the examiner’s mind and understand how to approach each question in such competitive exams. Totally recommended by me for those who wish to pursue their careers in reproductive medicine.”" */}
                  ""I am extremely grateful to Dr. Mona and Repro Neet for
                  providing the best guidance and mentorship during the entire
                  process of my preparation. I am confident that your teaching
                  is going to benefit a lot of students in near future. Thank
                  you so much for your genuine efforts in creating my success
                  story.""
                </p>
              </div>

              <div className="my-5 flex items-center">
                <div className="w-[70px]">
                  <img
                    src={require("../assets/img/girl-avatar.png")}
                    className="h-[60px]"
                    alt=""
                  />
                </div>

                <div className="text-left px-3">
                  <p className="font-bold text-xl my-1">Dr. Sunita Naik</p>
                  <p className="text-sm text-gray-500">
                    NEET SS 2021-2, Rank 5
                  </p>
                </div>
              </div>
            </div>

            {/* <div>
              <img src={s2} />
            </div>
            <div>
              <img src={s3} />
            </div>
            <div>
              <img src={s4} />
            </div>
            <div>
              <img src={s5} />
            </div> */}
          </Carousel>
        </div>
      </div>

      {/* success stories code end */}

      {/* Watch Testimonials Videos code start */}

      <div className="my-10 ">
        <div className="mb-10 mx-2 ">
          <p className="text-4xl md:text-5xl text-center font-bold">
            Watch Testimonials <span className="text-red-500">Videos</span>
          </p>
        </div>

        {/* <div className="md:flex md:items-center md:justify-center my-5">
          <div className="mx-5 border-4 border-gray-200  my-5">
            <img src={require('../assets/img/1.png')} alt="" />
          </div>

          <div className="mx-5 border-4 border-gray-200  my-5">
            <img src={require('../assets/img/2.png')} alt="" />
          </div>

          <div className="mx-5 border-4 border-gray-200  my-5">
            <img src={require('../assets/img/3-1.png')} alt="" />
          </div>
        </div> */}

        <div className="">
          <div className="flex flex-wrap w-90 self-center justify-center mt-20">
            {testimonial.map((item) => {
              return (
                <div className="w-[320px] h-[330px] bg-white shadow-sm drop-shadow-lg rounded-md mx-10 my-10 border-4">
                  <ReactPlayer
                    url={item.url}
                    width={305}
                    height={200}
                    className="md:w-[310px]"
                  />
                  <div className="flex flex-col px-5 py-5 text-center  ">
                    <p className="text-gray-900 font-bold text-xl">
                      {item.candidateName}
                    </p>
                    <p>
                      Rank {item.rank}, {item.course}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex px-10 item-center md:flex md:justify-center">
            <Link
              to="/testimonial"
              className="bg-gradient-to-r from-orange-300 to-red-500 rounded-full px-8 py-3 text-white md:px-10 my-5 hover:from-slate-200 hover:to-slate-200 hover:text-red-500 hover:ease-in duration-200"
            >
              Watch Testimonials
            </Link>
          </div>
        </div>
      </div>

      {/* Watch Testimonials Videos code end */}

      {/* Frequently Asked Questions code start */}

      <div className="md:mx-[100px] my-10">
        <div className="mb-10 mx-2 ">
          <p className="text-4xl md:text-5xl text-center font-bold">
            Frequently Asked
            <span className="text-red-500"> Questions</span>
          </p>
        </div>

        <div className="md:flex md:items-center md:justify-center ">
          <div className="">
            <img
              src={require("../assets/img/Student stress-pana.png")}
              className="w-[55rem] "
              alt=""
            />
          </div>

          <div className="mx-5 my-10 md:w-[40%]">
            <Accordion>
              <AccordionItem
                header="How Do I Contact The Mentor At Reproneet"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5 text-gray-500">
                  There are many ways to reach Dr. Monalisa for your clinical
                  doubts and to join latest discussions.
                  <ul className="mt-6 text">
                    <li className="text-left  mx-0 px-0">
                      Join telegram group and post your question here: <br></br>
                      <a
                        href="tg://join?invite=VzIpNbt8a_4yZDk1"
                        className=" mx-0 px-0"
                      >
                        tg://join?invite=VzIpNbt8a_4yZDk1
                      </a>
                    </li>

                    <li>
                      Join FB group to see MCQ of the day and to participate in
                      discussions: <br></br>
                      <a
                        href="https://www.facebook.com/groups/338257620576574/?ref=share"
                        className=" mx-0 px-0"
                      >
                        https://www.facebook.com/<br></br>
                        groups/338257620576574/?ref=share
                      </a>
                    </li>

                    <li>
                      Send an email at
                      <a
                        href="tg://join?invite=VzIpNbt8a_4yZDk1"
                        className=" mx-0 px-0"
                      >
                        {" "}
                        reproneet@gmail.com{" "}
                      </a>
                    </li>
                  </ul>
                </p>
              </AccordionItem>

              <AccordionItem
                header="How To Seek Help For Technical Issues With The Apps"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5">
                  Send a mail to support@testpress.in and CC to
                  reproneet@gmail.com Most issues will be resolved within 48
                  working hours of raising the complain.
                </p>
              </AccordionItem>

              <AccordionItem
                header="What Is Difference Between 1 Year Course & 9 Month Course  Of NEET SS & FET, INISSET"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5">
                  The contents of all the NEET SS/FET/INISSET courses are same.
                  The difference between 1 year course, 9 month course and crash
                  courses is only in terms of duration of subscription validity.
                  Link to attend live lectures will be provided to students of
                  the one year course. The same lectures will be uploaded on the
                  app and website for all registered students within 24 hours,
                  during the period of their subscription validity. Thus no one
                  misses anything!
                </p>
              </AccordionItem>

              <AccordionItem
                header="What Is The Difference Between PDCET Course & NEET SS/FET Course"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5">
                  The PDCET course covers syllabus for post DGO entrance test
                  for secondary DNB. It includes video lectures, notes and MCQs,
                  similar to the NEET SS/FET/INISSET course , except a few
                  topics in reproductive Medicine & gynecologic oncology which
                  are not included.
                </p>
              </AccordionItem>

              <AccordionItem
                header="What Are The Foundation Courses"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5">
                  Foundation courses are meant to help you prepare for
                  university exams at the end of your post graduation as well as
                  for the super speciality entrance exams like NEET SS, FET and
                  INISSET. The contents are essentially same as NEET
                  SS/FET?INISSET course with additional solved DNB Theory papers
                  of last 5 years
                </p>
              </AccordionItem>

              <AccordionItem
                header="What Are Focused Reproductive Medicine & Focused Gynecologic Oncology Courses"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5">
                  Each of these course only contain lectures and notes on topics
                  of the respective sub speciality. General OBG topics are not
                  covered. MCQs are same as other courses.
                </p>
              </AccordionItem>

              <AccordionItem
                header="How To Download The Videos & Notes From The App/Website"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3 text-left mx-0 px-0"
              >
                <p className="font-normal mt-5">
                  All the contents are provided online only. The videos, notes
                  or MCQs are not downloadable or printable. Candidates may read
                  directly from the app/website or may make their own notes,
                  depending on their preferred method of revising.
                </p>
              </AccordionItem>

              <AccordionItem
                header="What Are The Offers & How To Know About Them."
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3 text-left"
              >
                <p className="font-normal mt-5">
                  Offers are announced on FB and telegram groups.
                </p>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>

      {/* Frequently Asked Questions code end */}

      {/* Download Our App code start */}

      <div className="bg-gradient-to-r from-orange-300 to-red-400 my-10  ">
        <div className="md:flex md:items-center md:justify-center md:mx-96">
          <div className="m-5 ">
            <div>
              <p className="font-bold text-3xl  text-white md:text-6xl">
                {" "}
                Download Our App!
              </p>
              <p className="text-white my-10 ">
                Reproneet Learning is available across all platforms Android ,
                iOS , Web App helps student to learn on the go.It has features
                like mock test, offline video, live classes, study material,
                student analytics and notification so that student do not miss
                single update.
              </p>

              <div className="flex items-center mx-0 px-0">
                <Link to="" className="mx-0 px-2">
                  <img
                    src="https://reproneet.in/wp-content/uploads/2023/01/Google-play_img.png"
                    alt=""
                  />
                </Link>

                <Link to="" className="mx-0 px-2">
                  <img
                    src="https://reproneet.in/wp-content/uploads/2023/01/App-store.png"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="flex  justify-center md:w-[700px] md:my-16">
            <img
              src={require("../assets/img/img1.png")}
              className="h-[400px]"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* Download Our App code end */}

      {/* Awards and Activities code start */}

      <div className="my-10 ">
        <div className="mb-10 mx-2 ">
          <p className="text-4xl md:text-5xl text-center text-red-500 font-bold ">
            Awards and Activities
          </p>
        </div>

        <div className="md:flex md:items-center md:justify-center my-5">
          <div className="mx-5 border-4 border-gray-200  my-5">
            <img src={require("../assets/img/1.png")} alt="" />
          </div>

          <div className="mx-5 border-4 border-gray-200  my-5">
            <img src={require("../assets/img/2.png")} alt="" />
          </div>

          <div className="mx-5 border-4 border-gray-200  my-5">
            <img src={require("../assets/img/3-1.png")} alt="" />
          </div>
        </div>
      </div>

      {/* Awards and Activities code end */}

      <div>
        <Footer />
      </div>
    </div>
  );
};
