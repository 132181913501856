import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function MediaCard({
  courseId,
  title,
  description,
  thumbnail,
  totalDuration,
  courseDurationPriceResponse,
  filePath,
}) {
  const { userInfo } = useSelector((state) => state.userSlice);
  const navigate = useNavigate();

  const getCourseDetails = () => {
    navigate("/courseInfo", {
      state: {
        title,
        description,
        courseId,
        thumbnail,
        totalDuration,
        courseDurationPriceResponse,
      },
    });
  };

  return (
    <div className="relative shadow-lg flex  flex-col w-[28rem] mx-4 h-[40rem] max-md:w-96 px-2 rounded-2xl mb-5">
      <div className="w-full  flex justify-center h-[280px] ">
        <img
          alt="img"
          src={thumbnail}
          className="self-center  w-full h-[280px] rounded-lg"
        />
      </div>
      <div className="px-4 py-10">
        <p className="text-xl  font-semibold text-gray-600 max-md:text-base">
          {title}
        </p>

        <div className="mt-4">
          {description?.split(".")?.map((details) => {
            return (
              <div className="h-max-[220px]  overflow-hidden text-xs md:text-sm font-medium">
                <span className="text-red-500">★</span> {details}
              </div>
            );
          })}
        </div>
      </div>
      <div className="absolute bottom-10 mx-5">
        <button
          size="small"
          onClick={() => getCourseDetails()}
          className="bg-slate-700 px-4 rounded-md py-2 text-white font-semibold text-sm"
        >
          View Price
        </button>
        <button
          className=" bg-indigo-600 px-4 rounded-md py-2 text-white font-medium mx-4 text-sm"
          size="small"
          onClick={() => {
            const path = filePath?.substring(filePath?.lastIndexOf("/") + 1);
            // window.open(
            //   `https://www.reproneetlearning.com/course-viewer/${path}/web`,
            //   "_blank"
            // );
            window.open(
              `${filePath}`,
              "_blank"
            );
          }}
        >
          View Curriculum
        </button>
      </div>
    </div>
  );
}
