import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppConstant } from "../.environments";

const initialState = {
  userInfo: {},
  loading: false,
  error: "",
  errorMessage: "",
};

const LoginThunk = createAsyncThunk("login/loginUser", (payload) => {
  return axios
    .post(`${AppConstant.BASE_URL}/v1/api/user/signin`, payload)
    .then((res) => {
      return res.data.responseData;
    })
    .catch((error) => {
      return {
        hasError: true,
        error: error.response.data.responseData.message,
      };
    });
});

const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setUserProfile(state, action) {
      state.userInfo = action.payload;
    },
 
  },
  extraReducers: (builder) => {
    builder.addCase(LoginThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(LoginThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = "";

      if (action.payload.hasError === true) {
        state.error = action.payload.error;
      }
    });
    builder.addCase(LoginThunk.rejected, (state, action) => {
      state.loading = false;
      state.courses = [];
      state.error = action.error.message;
    });
  },
});

export const { setUserProfile } = userSlice.actions;

export default userSlice.reducer;
export { LoginThunk };
