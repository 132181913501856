import { useDispatch, useSelector } from "react-redux";
import {
  AttemptConstant,
  Circle,
  HorizontalPill,
  SemiCircle,
  SemiCircleInverted,
  SemiCircleInverted2,
} from "./AttemptType";
import {
  updateCurrentQuestion,
  updateQuestionAttemptState,
} from "../redux/QuestionReducer";
export const ExamSideBar = () => {
  const { loading, currentQuestion, questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });
  const dispatch = useDispatch();
  var currentQuestionIndex = 0;
  const handleClick = (itemId) => {
    currentQuestionIndex = itemId - 1;
    dispatch(updateCurrentQuestion(itemId - 1));
    if (
      questionsData?.questionRequest[itemId - 1]?.questionState ===
      AttemptConstant.PRESTINE
    ) {
      dispatch(updateQuestionAttemptState({ state: AttemptConstant.MARKED }));
    }
  };

  return (
    <div className=" h-[150px] lg:h-[640px] lg:w-[22rem] bg-slate-50 p-4 rounded-lg flex flex-col mt-[5px] max-md:w-full max-md:mt-2 max-md:mb-2  items-center">
      <p className=" flex  text-gray-800 text-lg font-semibold max-md:px-2 max-md:text-sm my-4">
        Choose Questions
      </p>

      <div className="lg:w-full overflow-scroll lg:justify-center flex flex-nowrap lg:flex-wrap">
        {questionsData?.questionRequest?.map((item, id) => {
          switch (item.questionState) {
            case AttemptConstant.PRESTINE:
              return (
                <HorizontalPill
                  callback={handleClick}
                  key={item?.questionId}
                  questionId={id + 1}
                  backgroundColor="red"
                />
              );

            case AttemptConstant.ANSWERED_REVIEW:
              return (
                <SemiCircleInverted
                  callback={handleClick}
                  key={item?.questionId}
                  questionId={id + 1}
                  backgroundColor="indigo"
                />
              );
            case AttemptConstant.NOT_ANSWER_REVIEW:
              return (
                <SemiCircleInverted2
                  callback={handleClick}
                  key={item?.questionId}
                  questionId={id + 1}
                  backgroundColor="indigo"
                />
              );
            case AttemptConstant.ANSWERED:
              return (
                <Circle
                  callback={handleClick}
                  key={item?.questionId}
                  questionId={id + 1}
                  backgroundColor="green"
                />
              );
            case AttemptConstant.MARKED:
              return (
                <SemiCircle
                  callback={handleClick}
                  key={item?.questionId}
                  questionId={id + 1}
                  backgroundColor="purple"
                />
              );
            default:
              return <div>Unexplained </div>;
          }
        })}
      </div>
    </div>
  );
};

export default ExamSideBar;
