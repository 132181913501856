import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppConstant } from "../.environments";

const initialState = {
  previousAttempted: {},
  loading: false,
  error: "",
};

const fetchPreviousExamData = createAsyncThunk(
  "test/fetchPreviousExamData",
  ({ chapterId, courseId, sectionId, userId }) => {
    return axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/chapter/get-test-response-by-user-id-and-chapter-id?chapterId=${chapterId}&courseId=${courseId}&sectionId=${sectionId}&userId=${userId}`
      )
      .then((res) => {
        console.log("Response", res);
      });
  }
);

const examAttemptReducer = createSlice({
  name: "examAttemptReducer",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPreviousExamData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPreviousExamData.fulfilled, (state, action) => {
      state.loading = false;
      state.previousAttempted = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPreviousExamData.rejected, (state, action) => {
      state.loading = false;
      state.previousAttempted = [];
      state.error = action.error.message;
    });
  },
});

export default examAttemptReducer.reducer;
export const { updateCurrentQuestion, updateQuestionAttemptState } =
  examAttemptReducer.actions;

export { fetchPreviousExamData };
