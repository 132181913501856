import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConstant } from "../../.environments";
import { Toolbar } from "../../toolbar/toolbar";

const PreviousTestScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [testResponseData, setTestResponseData] = useState([]);
  const [isApiResponseSuccessful, setIsApiResponseSuccessfull] =
    useState(false);
  useEffect(() => {
    getPreviousTestResponse();
  }, []);

  //? on start test click
  const onStartTestClick = () => {
    navigate("/test", {
      state: {
        chapterId: location?.state?.chapterId,
        sectionId: location?.state?.sectionId,
        courseId: location?.state?.courseId,
        userId: location?.state?.userId,
        sectionName: location?.state?.sectionName,
      },
    });
  };

  //? get previous test response
  const getPreviousTestResponse = async () => {
    await axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/chapter/get-test-response-by-user-id-and-chapter-id?courseId=${location?.state?.courseId}&sectionId=${location?.state?.sectionId}&chapterId=${location?.state?.chapterId}&userId=${location?.state?.userId}`
      )
      .then((res) => {
        setTestResponseData(res?.data?.responseData);
        setIsApiResponseSuccessfull(true);
      })
      .catch((error) => {
        setIsApiResponseSuccessfull(false);
        console.log("some thing went wrong");
      });
  };

  //? const fetch the test result with help of test id
  const getTestResponseByTestId = async (testId) => {
    await axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/chapter/get-test-response-by-test-id?testId=${testId}`
      )
      .then((res) => {
        navigate("/test-analytics", {
          state: {
            testSubmittedData: res?.data?.responseData,
          },
        });
      })
      .catch((error) => {
        console.log("Some error occurred", error);
      });
  };

  return (
    <div>
      <Toolbar />
      {isApiResponseSuccessful && testResponseData?.length > 0 && (
        <div className="flex flex-col lg:grid lg:grid-cols-4 lg:gap-4 lg:overflow-x-auto">
          {testResponseData?.map((value, key) => {
            return (
              <div
                key={key}
                className="p-5 m-10 rounded shadow hover:cursor-pointer flex items-center align-middle flex-wrap justify-center text-center"
                onClick={() => {
                  getTestResponseByTestId(value?.testId);
                }}
              >
                <img
                  src={require("../../assets/illustrations/total_marks.png")}
                  alt="total-marks"
                  width={100}
                  height={100}
                />
                <p>Submitted Date : {value?.submittedDate}</p>
              </div>
            );
          })}
        </div>
      )}
      {isApiResponseSuccessful && testResponseData?.length === 0 && (
        <p className="text-3xl flex text-center md:justify-center my-20">
          No previous test records found. Start a new test
        </p>
      )}
      {isApiResponseSuccessful && (
        <div className="flex justify-center my-20">
          <button
            className="bg-orange-500 w-[200px] transition duration-200 hover:bg-orange-700 h-[35px] md:h-[45px] text-xs md:text-base md:px-5 text-white font-bold rounded-lg flex items-center align-middle justify-center text-center align-center content-center"
            onClick={() => {
              onStartTestClick();
            }}
          >
            Start test
          </button>
        </div>
      )}
    </div>
  );
};

export default PreviousTestScreen;
