import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentQuestion,
  updateQuestionAttemptState,
} from "../redux/QuestionReducer";
import { AttemptConstant } from "./AttemptType";

export const AnswerView = () => {
  const dispatch = useDispatch();
  const { loading, currentQuestion, questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });

  const handleChange = (event) => {
    dispatch(
      updateQuestionAttemptState({
        state: AttemptConstant.ANSWERED,
        selectedOption: event.target.value,
      })
    );
  };

  const handleAnswerAndReview = () => {
    dispatch(
      updateQuestionAttemptState({ state: AttemptConstant.ANSWERED_REVIEW })
    );
  };

  const handleNextQuestion = () => {
    if (currentQuestion + 1 > questionsData?.questionRequest?.length - 1) {
      return;
    }
    dispatch(updateCurrentQuestion(currentQuestion + 1));
    if (
      questionsData?.questionRequest?.[currentQuestion]?.questionState ===
      AttemptConstant.PRESTINE
    ) {
      dispatch(updateQuestionAttemptState({ state: AttemptConstant.MARKED }));
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion - 1 < 0) {
      return;
    }
    dispatch(updateCurrentQuestion(currentQuestion - 1));
    if (
      questionsData?.questionRequest?.[currentQuestion]?.questionState ===
      AttemptConstant.PRESTINE
    ) {
      dispatch(updateQuestionAttemptState({ state: AttemptConstant.MARKED }));
    }
  };

  const handleNotAnswerAndReview = () => {
    if (
      questionsData?.questionRequest?.[currentQuestion]?.questionState ===
      AttemptConstant.ANSWERED
    ) {
      dispatch(
        updateQuestionAttemptState({
          state: AttemptConstant.ANSWERED_REVIEW,
          selectedOption:
            questionsData?.questionRequest?.[currentQuestion]?.selectedOption,
        })
      );
    } else {
      dispatch(
        updateQuestionAttemptState({ state: AttemptConstant.NOT_ANSWER_REVIEW })
      );
    }
  };

  return (
    <div className="w-full  ">
      <div className="w-full  h-full lg:h-full lg:mt-[5px] lg:mx-[10px] overflow-scroll mr-[px] rounded-lg bg-neutral-50   max-md:w-full">
        <div className=" mt-[5px] rounded-lg px-6 py-6 max-md:w-full max-md:min-h-[200px] max-md:mt-2  bg-neutral-50">
          <p className="font-semibold text-gray-900">Question Description</p>
          <div className="pt-50 text-gray-700  mt-10 ">
            <p className="text-lg max-md:text-sm">
              {`${currentQuestion + 1}). ${
                questionsData?.questionRequest?.[currentQuestion]?.questionName
              }`}
            </p>
            <div className="flex flex-col">
              {questionsData?.questionRequest?.[
                currentQuestion
              ]?.questionImageUrl?.map((questionImages) => {
                return <img src={questionImages.url} />;
              })}
            </div>
          </div>
        </div>
        <p className="px-10 lg:mt-10 mb-5 text-lg font-semibold text-gray-700 max-md:text-lg">
          Choose correct option:
        </p>
        <div className="px-5 flex flex-col font-normal">
          <div>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={(event) => handleChange(event)}
            >
              {questionsData?.questionRequest?.[
                currentQuestion
              ]?.optionRequest?.map((options) => {
                return (
                  <div>
                    <FormControlLabel
                      // sx={{ fontSize: "12px" }}
                      componentsProps={{ typography: { variant: "" } }}
                      fontSize={10}
                      value={options?.optionId}
                      checked={
                        questionsData?.questionRequest?.[currentQuestion]
                          ?.selectedOption === options?.optionId
                      }
                      control={<Radio />}
                      label={options?.optionName}
                    />
                    {options?.optionImageUrl?.map((option) => {
                      return <img src={option.url} alt="option_img" />;
                    })}
                  </div>
                );
              })}
            </RadioGroup>
          </div>
          {/* <div className="mt-10 text-gray-800">
            <p className="font-semibold max-md:text-sm mb-4">
              Correct Option :
            </p>
            {questionsData?.questionRequest?.[currentQuestion]?.questionState ===
              AttemptConstant.ANSWERED && (
              <div>
                <p className="max-md:text-sm">
                  {
                    questionsData?.questionRequest?.[currentQuestion]
                      ?.correctAnswer?.optionName
                  }
                </p>
                <p className="mt-4 mb-4 font-semibold max-md:text-sm">
                  Description:
                </p>
                <p className="max-md:text-sm text-justify">
                  {questionsData?.questionRequest?.[
                    currentQuestion
                  ]?.correctAnswer?.description
                    ?.split("•")
                    .map((desc) => (
                      <p className="font-medium">✓{desc}</p>
                    ))}
                </p>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className="w-full bg-slate-800 h-[100px] flex  rounded-b-lg  px-4 items-center justify-between ">
        <button
          onClick={handlePreviousQuestion}
          className="bg-orange-500 h-[35px] md:h-[45px] px-4 text-xs md:text-base md:px-5 text-white font-bold rounded-lg "
        >
          Previous
        </button>

        <button
          onClick={handleNotAnswerAndReview}
          className="bg-orange-700  h-[35px] md:h-[45px] px-4 text-xs md:text-base md:px-5 text-white font-bold rounded-lg"
        >
          Mark For Review
        </button>

        <button
          onClick={handleNextQuestion}
          className="bg-orange-500 h-[35px] md:h-[45px] px-4 text-xs md:text-base md:px-5 text-white font-bold rounded-lg "
        >
          Next
        </button>
      </div>
    </div>
  );
};
