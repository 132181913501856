import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Toolbar } from "../toolbar/toolbar";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
export const CourseDetails = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userSlice);
  const [directPayment, setDirectPayment] = useState(false);

  const handlePayment = (item) => {
    if (state.courseId === "2b847502cccc4d14a088e94515d7e0cc") {
     if (userInfo?.userId == null || userInfo?.userId === "undefined") {
       navigate("/login");
       return;
     }
      setDirectPayment(true);
    } else {
      displayRazorpay(item?.duration, item?.price);
    }
  };

  useEffect(() => {
    if (state === null || state === undefined) {
      navigate("/courses");
      return;
    }
  }, []);

  const displayRazorpay = async (duration, price) => {
    if (userInfo?.userId == null || userInfo?.userId === "undefined") {
      navigate("/login");
      return;
    }

    const url =
      process.env.REACT_APP_ENV === "production"
        ? "https://services.reproneetlearning.com/service/payment/orders"
        : "https://services.reproneetlearning.com/service/payment/orders";
    const body = {
      amount: price * 100,
      currency: "INR",
      userId: userInfo?.userId,
      courseId: state?.courseId,
      duration: duration,
    };
    const result = await axios.post(url, body);

    const { amount, id: order_id, currency } = result.data;

    console.log("api key", process.env.REACT_APP_PROD_API_KEY_RZR_PAY);
    const options = {
      key:
        process.env.REACT_APP_ENV === "production"
          ? process.env.REACT_APP_PROD_API_KEY_RZR_PAY
          : process.env.REACT_APP_PROD_API_KEY_RZR_PAY, // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: currency,
      name: "Reproneet Learning.",
      description: "Course Purchase " + state?.title,
      order_id: order_id,

      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          userId: userInfo.userId,
          courseId: state.courseId,
          duration: duration,
        };

        try {
          const result = await axios.post(
            process.env.REACT_APP_ENV === "production"
              ? "https://services.reproneetlearning.com/service/payment/success"
              : "https://services.reproneetlearning.com/service/payment/success",
            data
          );
          navigate("/payment-verification", {
            state: {
              status: "success",
              data,
              courseName: state.title,
            },
          });
        } catch (err) {
          navigate("/payment-verification", {
            state: {
              status: "failure",
              data,
            },
          });
        }
      },

      prefill: {
        name: userInfo?.firstName + " " + userInfo?.lastName,
        email: userInfo?.email,
        contact: userInfo?.contact,
      },

      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };


  const getPrice = (base) => {
    return  (base+base*.025).toString()
  }
  return (
    <div>
      <Toolbar />
      {directPayment && (
        <div className="z-10 absolute w-full  lg:w-[25%] xl:w-[30%]  h-[75%] md:h-[70%] rounded-2xl bg-white shadow-xl left-0 right-0 mx-auto flex  px-10 pt-6 pb-4 border border-t-indigo-600 border-t-[.5rem] flex-col items-center border-b-indigo-600 border-b-[0.5rem]">
          <div
            onClick={() => {
              setDirectPayment(false);
            }}
            className="absolute top-2 right-2 w-[30px] h-[30px] cursor-pointer "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              id="close"
            >
              <path
                fill="#6563ff"
                d="m13.414 12 3.293-3.293a1 1 0 0 0-1.414-1.414L12 10.586 8.707 7.293a1 1 0 0 0-1.414 1.414L10.586 12l-3.293 3.293a1 1 0 0 0 1.414 1.414L12 13.414l3.293 3.293a1 1 0 0 0 1.414-1.414Z"
              ></path>
              <path
                fill="#b2b1ff"
                d="M19.07 4.93A10 10 0 1 0 4.93 19.07 10 10 0 1 0 19.07 4.93Zm-2.363 10.363a1 1 0 1 1-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 0 1-1.414-1.414L10.586 12 7.293 8.707a1 1 0 0 1 1.414-1.414L12 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414L13.414 12Z"
              ></path>
            </svg>
          </div>
          <p className="text-3xl my-2 font-medium text-gray-600">UPI Payment</p>
          <QRCode
            className="scale-75"
            value={`upi://pay?pa=phynlabztech@axl&pn=phynlabz%20tech&mc=0000&mode=02&purpose=00&am=${getPrice(
              5000
            )}`}
          />

          <div className="flex flex-col items-start w-75">
            <table className="w-full">
              <tr className=" p-2 w-full">
                <td>Course Amount</td>
                <td> Rs. 5000</td>
              </tr>
              <tr className=" p-2 w-full">
                <td>Convenience Fee (2%)</td>
                <td> Rs. 100</td>
              </tr>
              <tr>
                <div className="h-[1px] my-2 mx-10 w-full self-center items-center bg-gray-500" />
              </tr>

              <tr className=" p-2 w-full font-semibold">
                <td>Total Price</td>
                <td> Rs. 5100</td>
              </tr>
            </table>
          </div>

          <p className="text-base my-4 font-medium text-gray-900">
            Scan using UPI QR
          </p>

          <div className="font-medium text-xs">
            <p>Instruction : </p>
            <p>
              After Making payment please share screenshot of
              transaction/payment at{" "}
              <span className="font-bold text-red-500">
                sales@reproneetlearning.com
              </span>
              , After Verfication of payment your course will be alloted.
            </p>
          </div>

          <img
            src={require("../assets/images/upis.png")}
            className="px-10 absolute bottom-0  scale-50"
          />
        </div>
      )}

      <div className=" w-screen bg-white h-auto md:h-screen flex justify-center  items-center flex-col  self-center">
        <div className="w-[screen]  h-auto flex flex-col  rounded-lg drop-shadow-sm md:px-6 py-6 border lg:w-[100%]  self-center">
          <img
            src={state?.thumbnail}
            alt="thumbnail"
            className=" md:scale-100 self-center max-md:w-full mb-10 md:h-[350px]"
          />
          <p className=" text-xl px-5 md:text-2xl font-bold text-gray-700  lg:text-base ">
            {state?.title}
          </p>
          {state?.description?.split("•").map((description) => (
            <p className="text-xs md:text-base px-5 py-2 text-start font-medium text-gray-800 lg:text-sm">
              -{description}
            </p>
          ))}
          <div className="flex flex-col md:flex-row md:flex-wrap w-full items-center justify-center self-center lg:w-[80%]">
            {state?.courseDurationPriceResponse?.reverse().map((item) => (
              <div className="w-full  md:w-[250px] lg:w-[200px]    h-[260px] flex flex-col md:mx-4  mt-4 px-10  md:rounded-2xl py-10 drop-shadow-sm bg-white  mb-2 ">
                <div className="w-full md:w-[250px] lg:w-[200px]  ">
                  <img
                    alt="book_img"
                    src={require("../assets/images/book.png")}
                    className="w-[60px] mb-5 z-1"
                  />
                  <p className="text-2xl font-bold text-gray-700">
                    Rs {item?.price?.toLocaleString("en-IN")}
                  </p>
                  <p className="max-md:text-sm  max-md:font-medium  text-lg font-bold text-gray-700">
                    Valid For {item?.duration} Days
                  </p>
                  <button
                    className="self-start hover:cursor-pointer mt-2 px-4 py-2 text-white rounded-full bg-orange-600 max-md:text-xs text-sm  max-md:font-medium"
                    onClick={() => handlePayment(item)}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
