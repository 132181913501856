import { useSelector } from "react-redux";
import { convertMinuteToHourAndMinute } from "../Utils/AppUtils";

const ExamSupportToolbar = () => {
  const { questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });

  return (
    <div className=" w-full p-4 lg:p-8 bg-red-50 lg:self-center rounded-md justify-center mb-5  lg:scale-100">
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col">
          <p className="font-bold">Section </p>
          <p className="font-bold">
            Marks{" "}
            <span className="font-semibold text-green-700">+4 Correct, </span>{" "}
            <span className="font-semibold text-red-700">-1 Incorrect</span>
          </p>
        </div>
        <div className="flex flex-col mx-5">
          <p className="font-bold">
            Duration
            <span className="font-semibold text-gray-700 mx-4">
              {convertMinuteToHourAndMinute(
                questionsData?.questionRequest?.length
              )}
            </span>{" "}
          </p>
          <p className="font-bold">
            Questions{" "}
            <span className="font-semibold text-green-700">
              {" "}
              {questionsData?.questionRequest?.length}{" "}
            </span>{" "}
          </p>
        </div>

        <div className="flex md:flex-row flex-col">
          <div className="font-bold flex items-center mx-5">
            <img
              className=" w-[50px]"
              src={require("../assets/images/not-attempted.png")}
              alt=""
              srcset=""
            />
            <span className="font-medium text-gray-700 mx-4">
              Not Attempted{" "}
            </span>{" "}
          </div>

          <div className="font-bold flex items-center mx-5">
            <img
              className="w-[50px]"
              src={require("../assets/images/green.png")}
              alt=""
              srcset=""
            />
            <span className="font-medium text-gray-700 mx-4">Attempted</span>{" "}
          </div>
          <div className="font-bold flex items-center mx-5">
            <img
              className=" w-[50px]"
              src={require("../assets/images/not-answered.png")}
              alt=""
              srcset=""
            />
            <span className="font-medium text-gray-700 mx-4">
              Attempted but not marked{" "}
            </span>{" "}
          </div>
          <div className="font-bold flex items-center mx-5">
            <img
              className=" w-[50px]"
              src={require("../assets/img/mark-for-review.png")}
              alt=""
              srcset=""
            />
            <span className="font-medium text-gray-700 mx-4">
              Marked For review{" "}
            </span>{" "}
          </div>
          <div className="font-bold flex items-center mx-5">
            <img
              className=" w-[50px]"
              src={require("../assets/img/Screenshot 2023-09-18 at 3.18.57 PM Background Removed.png")}
              alt=""
              srcset=""
            />
            <span className="font-medium text-gray-700 mx-4">
              Answered and <br />
              Marked For review{" "}
            </span>
          </div>
        </div>
        {/* <Countdown
          date={Date.now() + 10000}
          intervalDelay={1000}
          precision={3}
          renderer={(props) => <div>{props.total}</div>}
        /> */}
      </div>
    </div>
  );
};

export default ExamSupportToolbar;
