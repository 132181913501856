import React, { useEffect, useState } from "react";
import { Toolbar } from "../toolbar/toolbar";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import {
  createTestSeriesQuestionData,
  createTestSeriesQuestionNumberData,
  makeNetworkCallForGetQuestionByChapterById,
  makeNetworkCallUploadTestResponse,
  updateQuestionData,
  updateQuestionNumberData,
} from "../redux/action/TestSeriesAction";
import { RequestType } from "../network/RequestType";
import { ActionType } from "../redux/action/ActionType";
import { NetworkConstant } from "../network/NetworkConstant";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { Constant } from "../constant/constant";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AttemptConstant } from "../ExamModule/AttemptType";
import { useLocation, useNavigate } from "react-router-dom";
import TestSeriesModal from "./TestSeriesModal";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { secondsToHHMMSS } from "../Utils/AppUtils";

const TestSeries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const testSeriesState = useSelector((state) => state.testSeriesReducer);
  const apiState = useSelector((state) => state.apiStateReducer);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.RESET_TEST_SERIES_QUESTION_NUMBER_DATA,
        payload: [],
      });
      dispatch({
        type: ActionType.RESET_TEST_SERIES_QUESTION_DATA,
        payload: [],
      });
      dispatch({
        type: ActionType.RESET_GET_QUESTION_BY_CHAPTER_ID,
        payload: {},
      });
      dispatch({
        type: ActionType.RESET_UPLOAD_TEST_RESPONSE,
        payload: {},
      });
    };
  }, []);

  useEffect(() => {
    dispatch(
      makeNetworkCallForGetQuestionByChapterById(
        RequestType.GET,
        NetworkConstant.getQuestionByChapterId,
        location?.state?.chapterId,
        {},
        {}
      )
    );
  }, []);

  // create question number data and store it in state to fetch it from redux
  const createQuestionNumberDataAndStoreInState = () => {
    const questionNumberArrayData = [];
    for (
      var i = 0;
      i <
      testSeriesState.getQuestionsByChapterId?.responseData?.questionRequest
        ?.length;
      i++
    ) {
      const questionNumberData = {
        questionStatus: Constant.QUESTION_UNATTEMPTED,
        questionNumber: i + 1,
      };
      questionNumberArrayData.push(questionNumberData);
    }
    dispatch(createTestSeriesQuestionNumberData(questionNumberArrayData));
  };

  // create question data in store in state to fetch it from redux
  const createQuestionDataAndStoreInState = () => {
    const questionDataArrayData = [];
    for (
      var i = 0;
      i <
      testSeriesState.getQuestionsByChapterId?.responseData?.questionRequest
        ?.length;
      i++
    ) {
      const questionData = {
        questionData:
          testSeriesState.getQuestionsByChapterId?.responseData
            ?.questionRequest[i],
        selectedOptionId: "",
      };
      questionDataArrayData.push(questionData);
    }
    dispatch(createTestSeriesQuestionData(questionDataArrayData));
  };

  useEffect(() => {
    if (testSeriesState.getQuestionsByChapterId?.statusCode === 200) {
      createQuestionDataAndStoreInState();
      createQuestionNumberDataAndStoreInState();
    } else {
      console.log("Oh oh, some error occured");
    }
  }, [testSeriesState.getQuestionsByChapterId?.statusCode]);

  useEffect(() => {
    if (testSeriesState.uploadTestResponseData?.statusCode === 200) {
      handleOpen();
    } else {
      console.log("Oh oh, some error occured while uploading test series");
    }
  }, [testSeriesState.uploadTestResponseData?.statusCode]);

  //? on submit of exam
  const onSubmitOfExam = () => {
    let questionJsonRequest = [];
    for (var i = 0; i < testSeriesState.testSeriesQuestionData?.length; i++) {
      const questionValue =
        testSeriesState.testSeriesQuestionData[i]?.questionData;
      const selectedOptionId =
        testSeriesState.testSeriesQuestionData[i].selectedOptionId;

      var correctOptionId = "";
      for (var j = 0; j < questionValue?.optionRequest?.length; j++) {
        if (questionValue?.optionRequest[j]?.correct === true) {
          correctOptionId = questionValue?.optionRequest[j]?.optionId;
          break;
        }
      }
      let state = "";
      let selectedAnswer = "";
      if (selectedOptionId === undefined || selectedOptionId === "") {
        state = AttemptConstant.UNATTEMPTED;
      } else if (
        selectedOptionId !== undefined &&
        selectedOptionId !== "" &&
        selectedOptionId === correctOptionId
      ) {
        state = AttemptConstant.CORRECT;
      } else {
        state = AttemptConstant.INCORRECT;
      }
      if (selectedOptionId !== undefined && selectedOptionId !== "") {
        selectedAnswer = selectedOptionId;
      } else {
        selectedAnswer = null;
      }

      const jsonRequest = {
        questionId: `${questionValue?.questionId}`,
        correctAnswer: correctOptionId,
        selectedAnswer: selectedAnswer,
        state: state,
        timeTaken: "20s",
      };
      questionJsonRequest.push(jsonRequest);
    }

    const submitTestJsonRequest = {
      userId: location?.state?.userId,
      courseId: location?.state?.courseId,
      sectionId: location?.state?.sectionId,
      chapterId: location?.state?.chapterId,
      jsonRequest: questionJsonRequest,
    };

    console.log("fsfjslfs", submitTestJsonRequest);

    dispatch(
      makeNetworkCallUploadTestResponse(
        RequestType.POST,
        NetworkConstant.uploadTestResponse,
        submitTestJsonRequest,
        {}
      )
    );
  };

  // handle question number click
  const handleQuestinoNumberClick = (index) => {
    setCurrentQuestionNumber(index);
  };

  // handle radio button change
  const handleRadioButtonChange = (index, event) => {
    dispatch(updateQuestionData(index, event.target.value));
    dispatch(updateQuestionNumberData(index, Constant.QUESTION_ATTEMPTED));
  };

  useEffect(() => {
    if (apiState.showSnackBar) {
      const timer = setTimeout(() => {
        dispatch({
          type: ActionType.SHOW_SNACKBAR,
          payload: {
            showSnackBar: false,
            snackBarMessage: "",
            snackBarSeverity: Constant.SEVERITY_SUCCESS,
            apiError: false,
          },
        });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [apiState.showSnackBar]);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleButtonClick = () => {
    navigate(-1);
  };

  const onCompleteOfTimer = () => {
    onSubmitOfExam();
  };

  return (
    <>
      <div>
        <Toolbar />
        <div className="mx-5">
          <div className="w-full bg-gradient-to-r from-green-600 to-blue-500  h-[5rem] rounded-xl flex justify-center items-center px-5">
            <CountdownCircleTimer
              strokeWidth={2}
              size={75}
              isPlaying
              duration={
                testSeriesState.getQuestionsByChapterId?.responseData
                  ?.questionRequest?.length * 60
              }
              colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
              colorsTime={[20, 10, 5, 0]}
              onComplete={() => {
                onCompleteOfTimer();
              }}
            >
              {({ remainingTime }) => secondsToHHMMSS(remainingTime)}
            </CountdownCircleTimer>
            <div className="flex-1 flex justify-center">
              <p className="text-white text-xl font-bold">
                Total no of questions:{" "}
                {
                  testSeriesState.getQuestionsByChapterId?.responseData
                    ?.questionRequest?.length
                }
              </p>
            </div>

            <div>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => {
                  onSubmitOfExam();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="my-5 mr-5 p-5 w-4/5 h-[40rem] bg-gray-100 rounded-xl flex overflow-y-scroll">
              {testSeriesState.testSeriesQuestionData?.length > 0 && (
                <div>
                  <p>
                    {currentQuestionNumber + 1}.{" "}
                    {
                      testSeriesState.testSeriesQuestionData[
                        currentQuestionNumber
                      ]?.questionData?.questionName
                    }
                  </p>
                  <div className="flex flex-col">
                    {testSeriesState.testSeriesQuestionData[
                      currentQuestionNumber
                    ]?.questionData?.questionImageUrl?.map((questionImages) => {
                      return <img src={questionImages.url} />;
                    })}
                  </div>
                  <p className="lg:mt-10 mb-5 text-lg font-semibold text-gray-700 max-md:text-lg">
                    Choose correct option:
                  </p>
                  <div className="px-5 flex flex-col font-normal">
                    <div>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={(event) =>
                          handleRadioButtonChange(currentQuestionNumber, event)
                        }
                      >
                        {testSeriesState.testSeriesQuestionData[
                          currentQuestionNumber
                        ]?.questionData?.optionRequest?.map((options) => {
                          return (
                            <div>
                              <FormControlLabel
                                componentsProps={{
                                  typography: { variant: "" },
                                }}
                                fontSize={10}
                                value={options?.optionId}
                                checked={
                                  testSeriesState.testSeriesQuestionData[
                                    currentQuestionNumber
                                  ]?.selectedOptionId === options?.optionId
                                }
                                control={<Radio />}
                                label={options?.optionName}
                              />
                              {options?.optionImageUrl?.map((option) => {
                                return (
                                  <img src={option.url} alt="option_img" />
                                );
                              })}
                            </div>
                          );
                        })}
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="my-5 w-1/5 h-[40rem] bg-gray-100 rounded-xl overflow-y-scroll shadow-lg">
              <div className="grid grid-cols-3 gap-4 p-4">
                {testSeriesState?.testSeriesQuestionNumberData?.length > 0 &&
                  testSeriesState?.testSeriesQuestionNumberData?.map((data) => (
                    <div
                      key={data?.questionNumber}
                      onClick={() => {
                        handleQuestinoNumberClick(data?.questionNumber - 1);
                      }}
                      className={`${
                        data?.questionStatus === Constant.QUESTION_ATTEMPTED
                          ? "bg-green-500"
                          : "bg-white"
                      } p-2 rounded shadow text-center cursor-pointer`}
                    >
                      <p className="text-gray-800 font-semibold">
                        {data?.questionNumber}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {apiState.showLoader && (
        <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
          <CircularProgress />
        </div>
      )}
      <TestSeriesModal
        open={isModalOpen}
        handleClose={handleClose}
        title="Test submitted"
        description="Your test has been submitted successfully."
        buttonCallback={handleButtonClick}
      />
      {apiState.showSnackBar && (
        <div className="fixed top-4 right-4">
          <Alert variant="filled" severity="error">
            {apiState.snackBarMessage}
          </Alert>
        </div>
      )}
    </>
  );
};

export default TestSeries;
