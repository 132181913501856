import { Toolbar } from "../toolbar/toolbar";
import Footer from "../Footer/Footer";

export const Shipping = () => {
  return (
    <>
      <div className="">
        <Toolbar />

        <div className="">
          <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
            <p className="text-white text-4xl font-bold">
              {" "}
              Shipping & Delivery
            </p>
          </div>

          <div className=" flex justify-center bg-red-50 py-10">
            <div className="mx-10   px-5  ps-10 md:ps-0  md:w-[60%]">
              <ol className="">
                <li className="my-5  ">
                  

                  <ol className="px-5  mx-3 list-decimal text-base">
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      The online content will be available within 2 working days
                      of registration.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      This does not involve any shipping or delivery Shipping
                      and delivery - not applicable
                    </li>
                    
                  </ol>
                </li>

              </ol>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

