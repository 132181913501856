import axios from "axios";
import { RequestType } from "./RequestType";

export const axiosInstance = axios.create({
  baseURL: "https://student.reproneetlearning.com/",
});


const makeNetworkCall = async (requestType, endpoint, body = {}, config = {}) => {
  switch (requestType) {
    case RequestType.GET: {
      return await axiosInstance.get(endpoint, config);
    }
    case RequestType.POST: {
      return await axiosInstance.post(endpoint, body, config);
    }
    case RequestType.PUT: {
      break;
    }
    case RequestType.DELETE: {
      return await axiosInstance.delete(endpoint);
    }
    default: {
      console.log("Something went wrong");
    }
  }
};

export { makeNetworkCall };


