import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppConstant } from "../.environments";

const initialState = {
  enrolledCourseDetail: {},
  loading: false,
  error: "",
  resources: [],
};

const fetchCourseDetail = createAsyncThunk(
  "enrollment/fetchCourseDetail",
  ({ courseId, userId }) => {
    return axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/courses/getcoursesbyid?courseId=${courseId}&userId=${userId}`
      )
      .then((res) => {
        return res.data.responseData;
      });
  }
);

const enrollmentCourseDetail = createSlice({
  name: "enrolledCourseReducer",
  initialState,
  reducers: {
    updateCourse(state, action) {
      state.enrolledCourseDetail = action.payload; 
    },
    setResources(state, action) {
      state.resources = action.payload;
    },
    setClearCourse(state, action) {
      state.enrolledCourseDetail = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCourseDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCourseDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.enrolledCourseDetail = action.payload;
      state.error = "";
    });
    builder.addCase(fetchCourseDetail.rejected, (state, action) => {
      state.loading = false;
      state.enrolledCourseDetail = [];
      state.error = action.error.message;
    });
  },
});
export const { setResources, setClearCourse, updateCourse } =
  enrollmentCourseDetail.actions;
export default enrollmentCourseDetail.reducer;
export { fetchCourseDetail };
