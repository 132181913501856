import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./toolbarStyle.css";
import { useState } from "react";

import { setClearCourse } from "../redux/courseDetailSlice";
import reproneetLogo from "../assets/images/Reproneet Learning.svg";
import {
  MdAccountCircle,
  MdLogout,
  MdOutlineHome,
  MdOutlineNotifications,
  MdOutlinePersonOutline,
  MdOutlineSettings,
  MdOutlineSlowMotionVideo,
} from "react-icons/md";

import { TfiAlignRight } from "react-icons/tfi";
import {
  BookOutlined,
  LogoutOutlined,
  ReviewsOutlined,
} from "@mui/icons-material";
export const Toolbar = () => {
  const { userInfo, loading, error } = useSelector((state) => state.userSlice);
  const [isMenuVisible, setMenuVisible] = useState(false);

  const dispatch = useDispatch();
  const clearState = () => {
    dispatch(setClearCourse());
  };

  const [showCourseDropdown, setShowCourseDropdown] = useState(false);
  const [showAccountDropDown, setShowAccountDropDown] = useState(false);

  return (
    <div className=" relative flex w-full items-center px-10 bg-transparent z-[10]  top-0 max-md:pt-6 ">
      <img
        src={reproneetLogo}
        alt="logo"
        srcset=""
        width={130}
        height={135}
        className="max-md:w-[100px] max-md:-translate-y-6 max-md:-translate-x-6"
      />

      <div className="  w-full flex justify-end items-start h-10 max-sm:hidden z-99 mr-4">
        <ul className="hover:text-red-500">
          <Link to="/" title="Home" className="mx-4 px-0 font-medium">
            HOME
          </Link>
        </ul>

        <ul className="hover:text-red-500">
          <Link to="/careers" title="Home" className="mx-4 px-0 font-medium">
            CAREERS
          </Link>
        </ul>

        <ul
          onMouseEnter={() => setShowCourseDropdown(true)}
          onMouseLeave={() => setShowCourseDropdown(false)}
        >
          <Link
            to="/courses"
            title="Courses"
            className="mx-4 px-0 hover:text-red-500 font-medium "
          >
            COURSES
          </Link>

          {showCourseDropdown && (
            <div className="shadow-2xl blur-0 bg-white  mx-0 flex justify-start border-t-2 border-red-500 px-2">
              <ul className=" px-0 mx-0  mt-3">
                <li className="py-2  mx-0 px-0 left-0 hover:text-red-500">
                  <Link to="/courses" title="Courses" className="mx-0 px-0">
                    NEET SS
                  </Link>
                </li>

                <li className="py-2 hover:text-red-500">
                  <Link to="/courses" title="Courses" className="mx-0 px-0">
                    PDCET
                  </Link>
                </li>

                <li className="py-2 hover:text-red-500">
                  <Link to="/courses" title="Courses" className="mx-0 px-0">
                    MCH
                  </Link>
                </li>

                <li className="py-2 hover:text-red-500">
                  <Link to="/courses" title="Courses" className="mx-0 px-0">
                    FNB FET
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </ul>

        <ul className="hover:text-red-500">
          <Link
            to="/testimonial"
            title="Courses"
            className="mx-4 px-0 font-medium"
          >
            TESTIMONIALS
          </Link>
        </ul>
        <ul className="hover:text-red-500">
          <Link to="/toppers" title="Home" className="mx-4 px-0 font-medium">
            TOPPERS
          </Link>
        </ul>

        {userInfo.userId != null && (
          <ul>
            <Link
              to="/classroom"
              onClick={clearState}
              title="Classroom"
              className="mx-4 px-0 font-medium text-gray-700"
            >
              CLASSROOM
            </Link>
          </ul>
        )}
        {userInfo.userId == null ? (
          <ul>
            <Link to="/login" title="Login" className="hover:text-red-500 mx-8">
              LOGIN
            </Link>
          </ul>
        ) : (
          <ul
            className="w-[120px]"
            onMouseEnter={() => setShowAccountDropDown(true)}
            onMouseLeave={() => setShowAccountDropDown(false)}
          >
            <MdAccountCircle
              to="/"
              color="gray"
              width={100}
              height={100}
              title="Account"
              className="mx-8 px-0 hover:text-red-500  h-[30px] w-[30px]"
            >
              Account
            </MdAccountCircle>
            {showAccountDropDown && (
              <div className=" mt-2 shadow-2xl blur-0 bg-white  mx-0 flex justify-start border-t-2 border-red-500 px-2 w-[160px]">
                <ul className=" px-0 mx-0  mt-3">
                  <li className="py-2  mx-0 px-0 left-0 hover:text-red-500">
                    <Link
                      to="/notification"
                      title="Courses"
                      className="flex mx-0 px-0 items-center"
                    >
                      <MdOutlineNotifications className="mx-2" /> Notifications
                    </Link>
                  </li>

                  <li className="py-2 hover:text-red-500  ">
                    <Link
                      to="/settings"
                      title="Courses"
                      className="mx-0 px-0 flex items-center"
                    >
                      <MdOutlineSettings className="mx-2" /> Settings
                    </Link>
                  </li>
                  <li className="py-2  mx-0 px-0 left-0 hover:text-red-500">
                    <Link
                      to="/accounts"
                      title="Courses"
                      className="mx-0 px-0 flex items-center"
                    >
                      <MdOutlinePersonOutline className="mx-2" /> Profile
                    </Link>
                  </li>
                  <li className="py-2 hover:text-red-500">
                    <Link
                      onClick={() => {
                        localStorage.clear();
                      }}
                      className="mx-0 px-0 flex items-center "
                      to="https://www.reproneetlearning.com/login"
                      title="Logout"
                    >
                      <MdLogout className="mx-2" /> Logout
                    </Link>
                  </li>

                  <li className="py-2 mx-0 px-0 hover:text-red-500"></li>
                </ul>
              </div>
            )}
          </ul>
        )}
      </div>
      <div
        className="absolute z-40 top-5 right-5 sm:hidden"
        onClick={() => {
          setMenuVisible(!isMenuVisible);
        }}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2.5}
          stroke={isMenuVisible ? "purple" : "grey"}
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg> */}

        <TfiAlignRight />
      </div>
      {isMenuVisible && (
        <div class="absolute right-12 top-8 rounded-lg bg-white text-start shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ">
          <div class="border-b-2 border-neutral-100 px-6 py-3  dark:text-50">
            <Link
              to="/"
              title="Home"
              className="text-base font-normal mx-0 my-0  text-grey-200 flex items-center"
            >
              <MdOutlineHome className="mr-2 w-[25px] h-[25px]" /> HOME
            </Link>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3  dark:text-50 ">
            <Link
              to="/courses"
              title="Courses"
              className="text-base text-grey-200 mx-0 my-0 flex items-center"
            >
              <BookOutlined className="mr-2" /> COURSES
            </Link>
          </div>

          <div class="border-t-2 border-neutral-100 px-6 py-3  dark:text-50">
            <Link
              onClick={clearState}
              to="/testimonial"
              title="Testimonial"
              className="text-base text-grey-200 mx-0 my-0 flex items-center"
            >
              <ReviewsOutlined className="mr-2" /> TESTIMONIALS
            </Link>
          </div>
          {userInfo.userId != null && (
            <div class="border-t-2 border-neutral-100 px-6 py-3  dark:text-50">
              <Link
                onClick={clearState}
                to="/classroom"
                title="Classroom"
                className="text-base text-grey-200 mx-0 my-0 flex items-center"
              >
                <MdOutlineSlowMotionVideo className="mr-2  w-[25px] h-[25px]" />{" "}
                CLASSROOM
              </Link>
            </div>
          )}

          <div className="border-t-2 border-neutral-100 px-6 py-3  dark:text-50">
            {userInfo.userId == null ? (
              <ul className="px-0 dark:text-50">
                <Link
                  to="/login"
                  title="Login"
                  className="text-base text-grey-200 mx-0 my-0"
                >
                  LOGIN
                </Link>
              </ul>
            ) : (
              <ul className=" px-0 dark:text-50">
                <Link
                  onClick={() => {
                    localStorage.clear();
                  }}
                  to="https://www.reproneetlearning.com/login"
                  title="Logout"
                  className="text-base text-grey-200 mx-0 my-0"
                >
                  <LogoutOutlined /> Logout
                </Link>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const style = {};
