import React from "react";

import { Toolbar } from "../toolbar/toolbar";
import Footer from "../Footer/Footer";


 

function RefundPolicy() {
  return (
    <div>
      <Toolbar />

      <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
        <p className="text-white text-4xl md:text-5xl  font-bold">
          {" "}
          Cancellation and refund
        </p>
      </div>

      <div className="flex justify-center bg-red-200 py-10">
        <div className="mx-3 px-5 md:w-[60%]">
          <div className="">
            <ul className="">
              <li>
                <ol className="list-disc p-5">
                  <li className="text-lg my-5 md:text-lg">
                    A purchased course may be cancelled till 24 hours from
                    purchase
                  </li>
                  <li className="text-lg my-5 md:text-lg">
                    If you need to cancel a purchase and request a refund,
                    please send a mail to reproneet@gmail.com
                  </li>
                  <li className="text-lg my-5 md:text-lg">
                    The email will be responded to within 2 working days.
                  </li>
                  <li className="text-lg my-5 md:text-lg">
                    The entire refund will be issued if a valid reason for
                    cancellation is found and the request is made within 24
                    hours of purchase.
                  </li>
                  <li className="text-lg my-5 md:text-lg">
                    Refunds will not be issued and request will be rejected if
                    the above is not fulfilled
                  </li>
                  <li className="text-lg my-5 md:text-lg">
                    Payment mode of refund - online refund to source account
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default RefundPolicy;
