import React from 'react'

import Footer from "../Footer/Footer";
import { Toolbar } from "../toolbar/toolbar";
import { Link } from 'react-router-dom';


function Toppers() {

  

  const ReproductiveMedicineAndSurgery = [

    {
      candidateName: "Sunita Naik",
      rank: 5,
      seatAllotted: "Govt. Medical College, Thiruvananthapuram, MCh",
    },
    {
      candidateName: "Hemapriya Kaushik",
      rank: 15,
      seatAllotted: "Christian Medical College, Vellore, MCh",
    },
    {
      candidateName: "Shilpa",
      rank: 16,
      seatAllotted: "	Indira Gandhi Institute of Medical sciences, Patna, MCh",
    },
    {
      candidateName: "Nikita Bhargav",
      rank: 17,
      seatAllotted: "	Indira Gandhi Institute of Medical sciences, Patna, MCh",
    },
    {
      candidateName: "Naga Prathyusha",
      rank: 30,
      seatAllotted: "Kasturba Medical College, Manipal, MCh",
    },
    {
      candidateName: "Kavitha",
      rank: 69,
      seatAllotted: "GSL Medical College, MCh",
    },
    {
      candidateName: "Pranidha",
      rank: 113,
      seatAllotted: "Sri Ramachandra Medical College & Research Institute, MCh      ",
    },
    {
      candidateName: "Dhanya Vikhnan",
      rank: 24,
      seatAllotted: "	Kasturba Medical College, Manipal, MCh ",
    },
    {
      candidateName: "Pratyusha IndraKanti	",
      rank: 30,
      seatAllotted: "	Kasturba Medical College, Manipal, MCh ",
    },


  ]

  const GynecologicOncology = [

    {
      candidateName: "Navin Srinivasan",
      rank: 6,
      seatAllotted: "Kidwai Memorial Institute Of Oncology, MCh",
    },
    {
      candidateName: "Niraj Patil",
      rank: 9,
      seatAllotted: "Regional Cancer Center MCh",
    },
    {
      candidateName: "Richa",
      rank: 10,
      seatAllotted: "	Tata Medical Center DNBSS",
    },
    {
      candidateName: "Tusar Mahapatra	",
      rank: 14,
      seatAllotted: "	Acharya Harihar Post Graduate Institute of Cancer, MCh      ",
    },
    {
      candidateName: "Megha Nandawani	",
      rank: 41,
      seatAllotted: "Chittaranjan National Cancer Institute, Kolkata, DNBSS",
    },
    {
      candidateName: "Monal Garg	",
      rank: 48,
      seatAllotted: "Amrita School of Medicine, Kochi, MCh      ",
    },
    


  ]

  const neetss2021 = [

    {
      candidateName: "Dr. Asha Rani	",
      rank: 6,
      
    },
    {
      candidateName: "Dr. Durga Sai Vathsalya	",
      rank: 7,
      
    },
    {
      candidateName: "Dr. Pooja Chatterjee	",
      rank: 13,
      
    },
    {
      candidateName: "Dr. Suvidya Singh		",
      rank: 17,
      
    },
    {
      candidateName: "Dr. Kusuma Naik	",
      rank: 25,
      
    },
    {
      candidateName: "Dr. Radha	",
      rank: 28,
      
    },
    {
      candidateName: "Dr. Juhi	",
      rank: 29,
      
    }, 
    {
      candidateName: "Dr. Analise		",
      rank: 38,
      
    },
    {
      candidateName: "Dr. Swathi Sharma	",
      rank: 39,
      
    },
    {
      candidateName: "Dr. Ayushi	",
      rank: 43,
      
    },
    {
      candidateName: "Dr. Priyanka Parihar	",
      rank: 46,
      
    },
    {
      candidateName: "Dr. Tanu Sharma		",
      rank: 50,
      
    },
    
    


  ]


  const fet2021 = [

    {
      candidateName: "Anjali	",
      rank: 1,
      
    },
    {
      candidateName: "Nayana Hiremath",
      rank: 9,
      
    },
    {
      candidateName: "Bharati Priya		",
      rank: 15,
      
    },
    {
      candidateName: "Haripriya Kakumanu		",
      rank: 17,
      
    },
    {
      candidateName: "Sunidhi Minhas		",
      rank: 22,
      
    },
    {
      candidateName: "Shikha Marwah Pal		",
      rank: 28,
      
    }, 
    {
      candidateName: "Khushboo Goel			",
      rank: 31,
      
    },
    {
      candidateName: "Devyani Mukherjee	",
      rank: 35,
      
    },
    {
      candidateName: "Divya Jain		",
      rank: 44,
      
    },
    
    
    


  ]


  const pdcet = [

    {
      candidateName: "Nasna	",
      rank: 16,
      
    },
    {
      candidateName: " Aparna",
      rank: 26,
      
    },
    {
      candidateName: "Vandana",
      rank: 37,
      
    },
    {
      candidateName: " Saba		",
      rank: 41,
      
    },
    {
      candidateName: "Isha			",
      rank: 50,
      
    },
    {
      candidateName: "Bhoomika		",
      rank: 103,
      
    }, 
    {
      candidateName: "Manisha			",
      rank: 122,
      
    },
    {
      candidateName: "Namrata	",
      rank: 147,
      
    },
    {
      candidateName: "Upasana	",
      rank: 150,
      
    },
    
    
    


  ]



  return (
    <div>

      <Toolbar />

      <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
        <p className="text-white text-5xl font-bold"> Toppers</p>
      </div>


      <div className='my-10 md:flex md:justify-center'>

        <div className='flex items-center justify-center  my-3 '>
          <Link to="/" className='text-red-500 bg-white  border-2 border-red-400 hover:border-transparent hover:bg-red-500 hover:text-white transition-all duration-500 font-bold p-5 mx-2 w-[400px] md:w-[200px] text-center'  >
            NEET SS 2022
          </Link>

          <Link to="/" className=' text-red-500 bg-white border-2 border-red-400 hover:border-transparent hover:bg-red-500 hover:text-white transition-all duration-500 font-bold  p-5 mx-2 w-[400px] md:w-[200px] text-center' >
            NEET SS 2021
          </Link>
        </div>

        <div className='flex items-center justify-center   my-3 '>
          <Link to="/" className=' text-red-500 bg-white border-2 border-red-400 hover:border-transparent hover:bg-red-500 hover:text-white transition-all duration-500 font-bold  p-5 mx-2 w-[400px] md:w-[200px] text-center'  >
            FET 2021
          </Link>

          <Link to="/" className=' text-red-500 bg-white border-2 border-red-400 hover:border-transparent hover:bg-red-500 hover:text-white transition-all duration-500 font-bold  p-5 mx-2 w-[400px] md:w-[200px] text-center' >
            PDCET
          </Link>
        </div>

      </div>

      <div className='flex flex-col items-center justify-center mb-5'>
        <div className='md:w-1/2 md:px-5 '>
        <p className='text-2xl font-bold px-5 my-10  md:w-1/2'>Reproductive Medicine & Surgery</p>
        </div>

        <div className='px-3  md:px-10 md:w-1/2'>

          <table className='table-auto text-left  md:text-xl md:w-[850px]' >
            <thead className='px-5 '>
              <tr className='px-5 bg-red-500 text-white'>
                <th className='px-3 border-2 py-2'>Name</th>
                <th className='px-3 border-2 py-2'>Rank </th>
                <th className='px-3 border-2 py-2'>Seat Allotted</th>
              </tr>
            </thead>

            <tbody>

              {
                ReproductiveMedicineAndSurgery.map((data) => {
                  return (
                    <tr className='px-10 text-gray-500'>
                      <td className='px-3 border-2 py-2'>{data.candidateName}</td>
                      <td className='px-3 border-2 py-2'>{data.rank}</td>
                      <td className='px-3 border-2 py-2'>	{data.seatAllotted}</td>
                    </tr>
                  )
                })
              }

             
            </tbody>
          </table>

        </div>

      </div>

      <div className='flex flex-col items-center justify-center mb-10'>
        <div className='md:w-1/2 md:px-5 '>
        <p className='text-2xl font-bold px-5 my-10  md:w-1/2'>Gynecologic Oncology</p>
        </div>

        <div className='px-3  md:px-10 md:w-1/2'>

          <table className='table-auto text-left  md:text-xl md:w-[850px]' >
            <thead className='px-5 '>
              <tr className='px-5 bg-red-500 text-white'>
                <th className='px-3 border-2 py-2'>Name</th>
                <th className='px-3 border-2 py-2'>Rank </th>
                <th className='px-3 border-2 py-2'>Seat Allotted</th>
              </tr>
            </thead>

            <tbody>

              {
                GynecologicOncology.map((data) => {
                  return (
                    <tr className='px-10 text-gray-500'>
                      <td className='px-3 border-2 py-2'>{data.candidateName}</td>
                      <td className='px-3 border-2 py-2'>{data.rank}</td>
                      <td className='px-3 border-2 py-2'>	{data.seatAllotted}</td>
                    </tr>
                  )
                })
              }

             
            </tbody>
          </table>

        </div>

      </div>

      <div>
        <Footer/>
      </div>


    </div>
  )
}

export default Toppers
