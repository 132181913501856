import { useLocation, useNavigate } from "react-router-dom";
import { fetchQuestionForCourse } from "../redux/QuestionReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreviousExamData } from "../redux/ExamAttemptReducer";
import { convertMinuteToHourAndMinute } from "../Utils/AppUtils";
const TestScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [btnEnable, setBtnEnable] = useState(false);
  let location = useLocation();
    const { questionsData } = useSelector((state) => {
      return state?.questionReducer;
    });
  useEffect(() => {
    // dispatch(
    //   fetchPreviousExamData({
    //     chapterId: location?.state?.chapterId,
    //     userId: location?.state?.userId,
    //     sectionId: location?.state?.sectionId,
    //     courseId: location?.state?.courseId,
    //   })
    // );
    dispatch(fetchQuestionForCourse({ chapterId: location?.state?.chapterId }));
  }, []);

  const handleStartNewExam = () => {
    navigate("/start-test", {
      state: {
        chapterId: location?.state?.chapterId,
        userId: location?.state?.userId,
        sectionId: location?.state?.sectionId,
        courseId: location?.state?.courseId,
      },
    });
  };
  return (
    <div>
      <div className="relative w-[full] h-screen bg-red-50 flex flex-col p-4 ">
        <div className=" md:w-4/5 lg:w-2/3  self-center text-justify p-6 mt-10 h-5/6 text-lg overflow-y-scroll">
          <p className="font-bold text-base md:text-2xl">
            Please read the instructions carefully
          </p>
          <p className="font-semibold text-gray-700 text-base md:text-lg my-2 md:my-5">
            General Instructions
          </p>
          <ol className="text-xs md:text-lg ">
            <li>
              - Total of{" "}
              {convertMinuteToHourAndMinute(
                questionsData?.questionRequest?.length
              )}{" "}
              duration will be given to attempt all the questions
            </li>
            <li>
              - The question palette at the right of screen shows one ot the
              following statuses of each of the questions numbered:
            </li>
            <li> - You have not visited the question yet.</li>
            <li> - You have not answered the question.</li>
            <li> - You have answered the question.</li>
            <li>
              - You have NOT answered the question but have marked the question
              for review.
            </li>
            <li>
              - The question(s) "Answered and Marked for Review will be
              considered for evaluation.
            </li>
          </ol>
          <p className="text-xs md:text-lg">
            - The Marked for Review status simply acts as a reminder that you
            have set to look at the question again. If an answer is selected for
            a question that is Marked for Review, the answer will be considered
            in the final evaluation.
          </p>
          <div className="font-bold flex flex-col ">
            <p className="font-bold  text-xs md:text-lg mt-10 text-gray-700 mb-2">
              EXAM DETAILS ARE AS FOLLOWS :
            </p>
            <p className="font-bold text-xs md:text-lg">
              Topic:
              <span className="text-gray-700 italic mx-10 ">
                {location?.state?.sectionName?.toUpperCase().split(".")[1]}
              </span>
            </p>
            <p className="font-bold text-xs md:text-lg">
              Marks per question:
              <span className="mx-10 text-gray-700 italic text-lg">+4</span>
            </p>
            <p className="font-bold text-xs md:text-lg">
              Negative Marks:
              <span className="mx-10 text-gray-700 italic">-1</span>
            </p>

            <p className="font-bold text-xs md:text-lg">
              Duration:
              <span className="mx-10 text-gray-700 italic">
                {convertMinuteToHourAndMinute(
                  questionsData?.questionRequest?.length
                )}
              </span>
            </p>
            <p className="font-bold text-xs md:text-lg">
              Total Questions:
              <span className="mx-10 text-gray-700 italic">
                {questionsData?.questionRequest?.length}
              </span>
            </p>
          </div>
          <div className="flex items-center mt-4">
            <input
              type="checkbox"
              checked={btnEnable}
              className="mr-2  "
              onChange={() => {
                setBtnEnable(!btnEnable);
              }}
            />
            <p className="text-xs md:text-lg flex items-center font-semibold">
              I have read all the instructions and I agree to start new exam.
            </p>
          </div>
        </div>

        <button
          className={`absolute bottom-10  md:bottom-20 border-0 rounded-lg font-medium self-center text-white px-6 py-4 w-[250px] ${
            btnEnable
              ? "bg-red-500 cursor-pointer"
              : "bg-gray-500 cursor-not-allowed"
          } `}
          onClick={() => {
            handleStartNewExam();
          }}
        >
          Start New Exam
        </button>
      </div>
    </div>
    // <div className="w-full flex p-1 flex-col self-center ">
    //   <Toolbar />
    //   <Watermark
    //     content={["Reproneet Learning ", "Dr. Monalisa Singh"]}
    //     style={{ background: "#fff" }}
    //   >
    //     <div className="flex  self-center flex-col-reverse lg:flex-row max-md: mt-0">
    //       <div className="flex flex-col lg:flex-row mx-2">
    //         <QuestionView />
    //         <AnswerView />
    //       </div>
    //       <ExamSideBar />
    //     </div>
    //   </Watermark>
    // </div>
  );
};

export default TestScreen;
