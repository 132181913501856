import { ActionType } from "../../action/ActionType";

const initialState = {
  getQuestionsByChapterId: {
    statusCode: 0,
    responseData: "",
  },
  testSeriesQuestionNumberData: [],
  testSeriesQuestionData: [],
  uploadTestResponseData: {
    statusCode: 0,
    responseData: "",
  },
  getTestResponseByUserIdAndChapterId: {
    statusCode: 0,
    responseData: [],
  },
};

export const TestSeriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_QUESTION_BY_CHAPTER_ID:
      return {
        ...state,
        getQuestionsByChapterId: {
          statusCode: action.payload.getQuestionsByChapterId.statusCode,
          responseData: action.payload.getQuestionsByChapterId.responseData,
        },
      };
    case ActionType.RESET_GET_QUESTION_BY_CHAPTER_ID:
      return {
        ...state,
        getQuestionsByChapterId: {
          statusCode: 0,
          responseData: "",
        },
      };
    case ActionType.TEST_SERIES_QUESTION_NUMBER_DATA:
      return {
        ...state,
        testSeriesQuestionNumberData:
          action.payload.testSeriesQuestionNumberData,
      };
    case ActionType.RESET_TEST_SERIES_QUESTION_NUMBER_DATA:
      return {
        ...state,
        testSeriesQuestionNumberData: [],
      };
    case ActionType.TEST_SERIES_QUESTION_DATA:
      return {
        ...state,
        testSeriesQuestionData: action.payload.testSeriesQuestionData,
      };
    case ActionType.RESET_TEST_SERIES_QUESTION_DATA:
      return {
        ...state,
        testSeriesQuestionData: [],
      };
    case ActionType.UPDATE_SELECTED_ANSWER:
      const updatedQuestions = state.testSeriesQuestionData.map(
        (question, idx) => {
          if (idx === action.payload.index) {
            return {
              ...question,
              selectedOptionId: action.payload.selectedOptionId,
            };
          }
          return question;
        }
      );
      return {
        ...state,
        testSeriesQuestionData: updatedQuestions,
      };
    case ActionType.UPDATE_QUESTION_STATUS:
      const updatedQuestionNumberData = state.testSeriesQuestionNumberData.map(
        (questionNumberData, idx) => {
          if (idx === action.payload.index) {
            return {
              ...questionNumberData,
              questionStatus: action.payload.questionStatus,
            };
          }
          return questionNumberData;
        }
      );
      return {
        ...state,
        testSeriesQuestionNumberData: updatedQuestionNumberData,
      };
    case ActionType.UPLOAD_TEST_RESPONSE:
      return {
        ...state,
        uploadTestResponseData: {
          statusCode: action.payload.uploadTestResponseData.statusCode,
          responseData: action.payload.uploadTestResponseData.responseData,
        },
      };
    case ActionType.RESET_UPLOAD_TEST_RESPONSE:
      return {
        ...state,
        uploadTestResponseData: {
          statusCode: 0,
          responseData: "",
        },
      };
      case ActionType.TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID:
      return {
        ...state,
        getTestResponseByUserIdAndChapterId: {
          statusCode: action.payload.getTestResponseByUserIdAndChapterId.statusCode,
          responseData: action.payload.getTestResponseByUserIdAndChapterId.responseData,
        },
      };
    case ActionType.RESET_TEST_RESPONSE_BY_USER_ID_AND_CHAPTER_ID:
      return {
        ...state,
        getTestResponseByUserIdAndChapterId: {
          statusCode: 0,
          responseData: [],
        },
      };
    default:
      return state;
  }
};
