import { Toolbar } from "../toolbar/toolbar";
import React from 'react'

import ReactPlayer from "react-player";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";


import { AiOutlinePlus } from 'react-icons/ai'
import Footer from "../Footer/Footer";

function CareersInOBG() {

  const Careers = [
    {
      candidateName: "Dr. Sunita Naik",
      rank: 5,
      course: "NEET SS 2021",
      url: "https://youtu.be/_IKKYxt0-rE?si=BRsxrWC0viikRdA2",
    },
  ]

  return (
    <div>

      <Toolbar />

      <div className="w-full bg-gradient-to-r from-orange-300 to-red-400  h-[12rem] flex justify-center items-center ">
        <p className="text-white text-5xl font-bold"> Careers In OBG</p>
      </div>

      {/* ............................. */}

      <div className="w-full  flex flex-col md:flex-row md:items-center md:justify-center md  mx-0 px-0 md:my-10">

        <div className="px-5 my-5 ">

          <div className=" flex px-3 ">
            <div className="flex   flex-col  text-4xl  ">
              <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
                Information For  <span className="text-red-600 hover:text-black ">PG Students</span>
              </p>
              <p className="text-base font">
                What are Super speciality field options after PG in Obs & Gyn.
              </p>
            </div>
          </div>

          <div className="flex items-center my-3 mt-10">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-red-600" />
            </div>
            <p className="font-base">
              Reproductive medicine & surgery (infertility and IVF related) - MCh, DM, FNB

            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-red-600" />
            </div>
            <p className="font-base">
              Gynaecological oncology - MCh, DNB SS
            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-red-600" />
            </div>
            <p className="font-base">
              High risk pregnancy (Maternal and Fetal Medicine) - FNB
            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-red-600" />
            </div>
            <p className="font-base">

              Fetal medicine
            </p>
          </div>

          <div className="flex items-center my-3">
            <div className=" flex justify-center items-center mx-2">
              <AiOutlinePlus className="text-red-600" />
            </div>
            <p className="font-base">

              Gynecologic endoscopy - laparoscopy & hysteroscopy
            </p>
          </div>
        </div>

        <div className="flex flex-wrap  self-center justify-center mt-50 px-0 mx-0 ">
          {Careers.map((item) => {
            return (
              <div className="w-[320px] h-[220px]  shadow-sm drop-shadow-lg rounded-md  my-10 ">
                <ReactPlayer url={item.url} width={320} height={250} />

              </div>
            );
          })}
        </div>

      </div>

      {/* ............................. */}


      {/* ............................. */}


      <div className="flex flex-col md:flex-row md:justify-center px-4 mt-10 ">

        <div className="">
          <img src={require('../assets/img/slider1.jpeg')} className="" alt="" />
        </div>

        <div className=" flex px-3 md:w-1/3 md:px-5 text-gray-500">
          <div className="flex   flex-col  text-4xl  ">
            <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
              Gynecologic <span className="text-red-600 hover:text-black ">Oncology</span>
            </p>
            <p className="text-base font">
              You may aim for a 3 year MCh or DNB SS course in gynecologic oncology. Through these three years you will gain knowledge & exposure in management of all gynecologic cancers. These are the highest educational degrees in this field in the country.

              There were few seats couple of years ago, but since the introduction of NEET SS in obs & gyn, the number of seats of both MCh & DNB SS have increased & it has become a very achievable goal now!
            </p>

            <div className="mt-5 ">
              <p className="font-bold text-lg my-5">Exams to take</p>
              <ul>

                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> NEET SS</li>
                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> INISSET</li>
              </ul>
            </div>
          </div>

        </div>

      </div>


      {/* ............................. */}


      {/* ............................. */}


      <div className="flex flex-col-reverse md:flex-row md:justify-center px-4 my-10 md:my-0">



        <div className=" flex px-3 md:w-1/3 md:px-5 md:mx-0  text-gray-500">
          <div className="flex   flex-col  text-4xl  ">
            <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
              Reproductive  <span className="text-red-600 hover:text-black ">Medicine</span>
            </p>
            <p className="text-base font">
              The courses that may aim in field of reproductive medicine in India are:

              <ul className="my-5" style={{ listStyleType: "disc" }} >
                <li>MCh – Reproductive Medicine and Surgery (3 years)</li>
                <li>DM – Reproductive medicine (3 years)</li>
                <li>FNB – Reproductive Medicine (2 years)</li>
              </ul>

              During courses you will learn evaluation & management methods of infertile couples including hormonal ovarian stimulation, procedures like IUI, IVF/ICSI. You will get exposure to what goes on in embryology laboratory. Reproductive endoscopic surgeries – laparoscopy & hysteroscopy are parts of curriculum.
            </p>

            <div className="mt-3 ">
              <p className="font-bold text-lg my-5">Exams to take</p>
              <ul>

                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> NEET SS</li>
                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> INISSET</li>
                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> FET</li>
              </ul>
            </div>
          </div>

        </div>

        <div className=" ">
          <img src={require('../assets/img/slider2.jpeg')} className="" alt="" />
        </div>

      </div>


      {/* ............................. */}


      {/* ............................. */}


      <div className="flex flex-col md:flex-row md:justify-center px-4  ">

        <div className="">
          <img src={require('../assets/img/slider3.jpeg')} className="" alt="" />
        </div>

        <div className=" flex px-3 md:w-1/3 md:px-10 text-gray-500 ">
          <div className="flex   flex-col  text-4xl  ">
            <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
              Maternal &  <span className="text-red-600 hover:text-black ">Fetal Medicine</span>
            </p>
            <p className="text-base font">

              <ul style={{ listStyleType: "disc" }}><li className="text-base  my-5">FNB – Maternal and Fetal Medicine (2 years)</li></ul>
              During this 2 year course, you will learn management of high risk pregnancies, medical disorders in pregnancy and fetal management. It’s an upcoming branch with high demand.
            </p>

            <div className="mt-5 ">
              <p className="font-bold text-lg my-5">Exams to take</p>
              <ul>

                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> FET SS</li>

              </ul>
            </div>
          </div>

        </div>

      </div>


      {/* ............................. */}


      {/* ............................. */}


      <div className="flex flex-col-reverse md:flex-row md:justify-center px-4 my-10 md:my-0 ">



        <div className=" flex px-3 md:w-1/3 md:px-5 md:mx-0  text-gray-500 my-10">
          <div className="flex   flex-col  text-4xl  ">
            <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
              Secondary   <span className="text-red-600 hover:text-black ">DNB In OBG</span>
            </p>
            <p className="text-base font">
              If you have completed diploma in OBG (DGO), it is highly recommended that you take the PDCET exam and join a secondary DNB course. It’s a 2 year course. Completing secondary DNB makes your qualification equivalent to MD/MS OBG in India.
            </p>

            <div className="mt-5 ">
              <p className="font-bold text-lg my-5">Exams to take</p>
              <ul>

                <li className="text-base flex flex-row my-1"><AiOutlinePlus className="text-red-600 mx-2 font-bold" /> PDCET SS</li>

              </ul>
            </div>
          </div>

        </div>

        <div className=" ">
          <img src={require('../assets/img/slider4.jpeg')} className="" alt="" />
        </div>

      </div>




      {/* ............................. */}


      {/* ............................. */}


      <div className="flex flex-col md:flex-row md:justify-center px-4  ">

        <div className="">
          <img src={require('../assets/img/slider5.jpeg')} className="" alt="" />
        </div>

        <div className=" flex px-3 md:w-1/3 md:px-10 text-gray-500 md:my-10">
          <div className="flex   flex-col  text-4xl  ">
            <p className="text-black font-bold my-5 line-clamp-2 lg:line-clamp-none">
              University Exam   <span className="text-red-600 hover:text-black ">In OBG</span>
            </p>
            <p className="text-base font">

              These are mandatory university exams at the end of your courses like MD/MS/DNB/DGO. The exam consists of theory papers and practical assessment mostly in the for of OSCEs. The study material provided at Reproneet Learning will be immensely helpful as a source of of study material for you university exams
            </p>

          </div>

        </div>

      </div>


      {/* ............................. */}


      {/* ............................. */}


      <div className="">


        <div className="flex justify-center items-center">
        <div className="w-full lg:w-1/2 px-4 md:px-0 flex justify-center items-center flex-col self-center   md:text-gray-800 my-10 ">
          <p className=" text-3xl md:text-5xl my-10 font-bold  ">
            Exam
            <span className="text-red-500"> Syllabuses
            </span>
          </p>

          <p className="text-sm md:text-lg">
            Last ascertained in Jan 23
          </p>


        </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:justify-center  ">

          <div className="md:w-1/3 flex  justify-center">
            <img src={require('../assets/img/img5.png')} className="h-[50vh]" alt="" />
          </div>


          <div className="mx-5 my-5 md:w-[30%] ">
            <Accordion >
              <AccordionItem
                header="NEET SS"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3 " 
              >
                <p className="font-normal mt-5 text-white px-2 border-t-2">

                  <ul className="mt-6 text" style={{ listStyleType: "disc" }}>

                    <li className="text-left  mx-0 px-0 my-3">
                      NEET SS is the entrance exam to gain admission in

                      <ul style={{ listStyleType: "number" }}>
                        <li className="my-3">MCh & DNB SS Gynecologic Oncology</li>
                        <li className="my-3"> MCh Reproductive Medicine & Surgery</li>
                      </ul>

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      One exam for both sub specialities

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      150 questions in 150 minutes
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Negative marking for incorrect answers (1/4)
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Distribution of questions according to brochure – cover the General/ Basic component and all sub-specialty/systems/components of respective primary feeder broad specialty subject. (not divided as 40/60 like last year)
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Level of questions – PG exit exam level
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Note – the distribution of question and levels of questions may be variable and is unpredictable
                    </li>


                  </ul>
                </p>
              </AccordionItem>

              <AccordionItem
                header="FET"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5 text-white px-2 border-t-2">

                  <ul className="mt-6 text" style={{ listStyleType: "disc" }}>

                    <li className="text-left  mx-0 px-0 my-3">
                      FET is the entrance exam for 2 year FNB courses in

                      <ul style={{ listStyleType: "number" }}>
                        <li className="my-3">Reproductive Medicine</li>
                        <li className="my-3">  Maternal & Fetal Medicine</li>
                      </ul>

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      One exam for both sub specialities
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      100 questions, 2 parts Part A: 40 questions, Part B: 60 questions

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Duration 105 minutes, 2 parts – 45 min and 60 min.
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Negative marking for incorrect answers (1/4)
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Distribution of questions according to brochure – 40 questions from General OBG, 30 questions from reproductive Medicine and 30 from Maternal/fetal medicine
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Level of questions – PG exit exam level
                      <img src={require('../assets/img/Exam-Syllabuses.png')} alt="" />
                    </li>


                  </ul>

                </p>
              </AccordionItem>

              <AccordionItem
                header="INISSET"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5 border-t-2">

                  <ul className="mt-6 text" style={{ listStyleType: "disc" }}>

                    <li className="text-left  mx-0 px-0 my-3">
                      INISSET is the entrance exam for admissions at AIIMS New Delhi in

                      <ul style={{ listStyleType: "number" }}>
                        <li className="my-3"> MCh Gynecologic Oncology</li>
                        <li className="my-3">  DM Reproductive Medicine</li>
                      </ul>

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Stage I – MCQs, Stage II – departmental assessment.
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      2 separate Stage I exams, one for each subspeciality

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Stage I - questions from the General/Basic component of the qualifying educational qualification and from sub- specialty/systems/component the specialty/course for which the examination is being conducted. (from the prospectus)
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Generally each stage I paper has 10-20 questions from general OBG, which are same for both papers and remaining questions from the respective subspeciality.
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Negative marking for incorrect answers (1/3)
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      To qualify for stage II, candidate has to score at least 50% marks in stage I
                    </li>
                    <li className="text-left  mx-0 px-0 my-3">
                      Total number of candidates called for the Stage II – 3 times the number of seats available
                    </li>


                  </ul>

                </p>
              </AccordionItem>

              <AccordionItem
                header="DNB-PDCET"
                className="bg-gradient-to-r from-orange-300 to-red-400 rounded-lg px-5 py-5 font-bold text-white my-3"
              >
                <p className="font-normal mt-5 border-t-2">

                  <ul className="mt-6 text" style={{ listStyleType: "disc" }}>



                    <li className="text-left  mx-0 px-0 my-3">
                      PDCET is the entrance exam to gain admission in 2 year secondary DNB course after DGO
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      2 separate Stage I exams, one for each subspeciality

                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      120 questions in obstetrics and gynecology covering the entire curriculum relevant to the Diploma specialty.
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Duration – 120 minutes
                    </li>

                    <li className="text-left  mx-0 px-0 my-3">
                      Negative marking for incorrect answers
                    </li>




                  </ul>

                </p>
              </AccordionItem>


            </Accordion>
          </div>

        </div>

        <div className="mt-10">
          <Footer/>
        </div>


      </div>


      {/* ............................. */}





    </div>


  )
}

export default CareersInOBG
