import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { secondsToHHMMSS } from "../Utils/AppUtils";
import { useSelector } from "react-redux";
import { AttemptConstant } from "./AttemptType";
import axios from "axios";
import { AppConstant } from "../.environments";
import { useNavigate } from "react-router-dom";

const ExamHeaderCountDownSubmitToolbar = ({ courseData }) => {

  const navigate = useNavigate();


  const { questionsData } = useSelector((state) => {
    return state?.questionReducer;
  });
  const onCompleteOfTimer = () => {
    onSubmitOfExam();
  };

  //? submit test response api call
  const submitTestResponseApiCall = async (submitTestJsonRequest) => {
    await axios
      .post(
        `${AppConstant.BASE_URL}/v1/api/chapter/upload-test-response`,
        submitTestJsonRequest,
        {}
      )
      .then((res) => {
        getTestResponseByTestId(res?.data?.responseData);
      })
      .catch((error) => {
        console.log("Some error occurred", error);
      });
  };

  //? const fetch the test result with help of test id
  const getTestResponseByTestId = async (testId) => {
    await axios
      .get(
        `${AppConstant.BASE_URL}/v1/api/chapter/get-test-response-by-test-id?testId=${testId}`
      )
      .then((res) => {
        navigate("/test-analytics", {
          state: {
            testSubmittedData: res?.data?.responseData
          }
        });
      })
      .catch((error) => {
        console.log("Some error occurred", error);
      });
  };

  //? on submit of exam
  const onSubmitOfExam = () => {
    let questionJsonRequest = [];
    for (var i = 0; i < questionsData?.questionRequest?.length; i++) {
      const questionValue = questionsData?.questionRequest[i];
      let state = "";
      let selectedAnswer = "";
      if (
        questionValue?.questionState === undefined ||
        (questionValue?.questionState !== AttemptConstant.ANSWERED &&
          questionValue?.questionState !== AttemptConstant.ANSWERED_REVIEW)
      ) {
        state = AttemptConstant.UNATTEMPTED;
      } else if (
        questionValue?.selectedOption !== undefined &&
        questionValue?.selectedOption !== "" &&
        questionValue?.selectedOption === questionValue?.correctAnswer?.optionId
      ) {
        state = AttemptConstant.CORRECT;
      } else {
        state = AttemptConstant.INCORRECT;
      }
      if (
        questionValue?.selectedOption !== undefined &&
        questionValue?.selectedOption !== ""
      ) {
        selectedAnswer = questionValue?.selectedOption;
      } else {
        selectedAnswer = null;
      }

      const jsonRequest = {
        questionId: `${questionValue?.questionId}`,
        correctAnswer: `${questionValue?.correctAnswer?.optionId}`,
        selectedAnswer: selectedAnswer,
        state: state,
        timeTaken: "20s",
      };
      questionJsonRequest.push(jsonRequest);
    }

    const submitTestJsonRequest = {
      userId: courseData?.userId,
      courseId: courseData?.courseId,
      sectionId: courseData?.sectionId,
      chapterId: courseData?.chapterId,
      jsonRequest: questionJsonRequest,
    };
    submitTestResponseApiCall(submitTestJsonRequest);
  };

  return (
    <div className="w-full flex items-center align-middle flex-row p-4 lg:p-8 rounded-md justify-between">
      <CountdownCircleTimer
        strokeWidth={5}
        size={100}
        isPlaying
        duration={questionsData?.questionRequest?.length * 60}
        colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
        colorsTime={[20, 10, 5, 0]}
        onComplete={() => {
          onCompleteOfTimer();
        }}
      >
        {({ remainingTime }) => secondsToHHMMSS(remainingTime)}
      </CountdownCircleTimer>
      <button
        className="bg-orange-500 w-[120px] md:w-[auto] transition duration-200 hover:bg-orange-700 h-[35px] md:h-[45px] text-xs md:text-base md:px-5 text-white font-bold rounded-lg"
        onClick={() => {
          onSubmitOfExam();
        }}
      >
        Submit Exam
      </button>
    </div>
  );
};

export { ExamHeaderCountDownSubmitToolbar };
