import axios from "axios";
import { useState } from "react";
import OtpInput from "react-otp-input";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { AppConstant } from "../.environments";
export const Registration = () => {
  const [formStep, setFormStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [number, setNumber] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const requestOtp = async () => {
    if (
      email &&
      number &&
      email.trim().length > 0 &&
      number.trim().length > 0
    ) {
      try {
        const { data } = await axios.post(
          AppConstant.BASE_URL + AppConstant.SIGN_UP,
          {
            email,
            number,
          }
        );
        setShowError(false);
        setErrorMessage("");
        setShowOtp(true);
      } catch (e) {
        setErrorMessage(`${e.response.data.responseData.errorType} 
          ${e.response.data.responseData.message}`);
        setShowError(true);
      }
      // setFormStep(2);
    }
  };

  const verifyOTP = async () => {
    if (otp === null || otp === undefined || otp.length < 4) {
      setShowError(true);
      setErrorMessage("Please enter verification code");
      return;
    }
    try {
      const { data } = await axios.post(
        `${AppConstant.BASE_URL}/v1/api/user/verifyotp?email=${email}&otp=${otp}`,
        {}
      );
      setFormStep(2);
    } catch (e) {
      setErrorMessage("Incorrect OTP or OTP is expired");
      setShowError(true);
    }
  };
  const finalSubmit = async () => {
    if (otp === null || otp === undefined || otp.length < 4) {
      setShowError(true);
      setErrorMessage("Please enter verification code");
      return;
    }
    const body = {
      firstName,
      lastName,
      email,
      number,
      password,
      admin: false,
    };
    try {
      const { data, status } = await axios.post(
        AppConstant.BASE_URL + AppConstant.SAVE_USER_DETAIL,
        body
      );
      if (status === 200) {
        setShowConfetti(true);
        setTimeout(
          () => {
            navigate("/");
          },

          5000
        );
      }
    } catch (e) {
      setErrorMessage("Something went wrong");
      setShowError(true);
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      {showConfetti && (
        <Confetti
          style={{
            width: "100vw",
            height: "100vh",
          }}
        />
      )}
      <div className=" absolute flex flex-col   max-lg:w-[60%]  md:w-[500px] md:h-[auto] rounded-lg shadow-lg py-10 border max-md:text-sm bg-orange-50 ">
        <p className="font-bold text-5xl text-gray-800 mx-5 self-center mt-4 mb-4 max-md:text-3xl">
          Signup
        </p>
        <p className="font-bold text-xl text-gray-600 mx-5 self-center mt-0 mb-4 max-md:text-lg">
          Reproneet
        </p>

        {formStep === 1 && (
          <div className="mb-3 flex flex-col mt-2  w-full items-center">
            <input
              className="form-control border-2 px-4 py-4 w-4/5 mt-5 rounded-lg border-gray-300"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              placeholder="Email"
            />
            <input
              className="form-control border-2 px-4 py-4 w-4/5 mt-5 rounded-lg border-gray-300 "
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              name="number"
              placeholder="Mobile Number"
            />

            {showOtp && (
              <div className="mb-3 flex flex-col mt-2  w-full items-center">
                <p className="mt-10 mb-5 text-center font-bold">
                  Enter Verification code<br></br>that you received on mail
                </p>
                <OtpInput
                  className="form-control border-1 px-4 py-4 w-4/5 rounded-lg border "
                  value={otp}
                  onChange={setOtp}
                  inputStyle={{
                    border: "2px solid grey",
                    borderRadius: 10,
                    padding: 8,
                    width: 50,
                    height: 50,
                  }}
                  numInputs={4}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            )}
            {showOtp === false ? (
              <button
                className="mt-10 bg-blue-800 text-white px-8 py-2 rounded-lg"
                onClick={requestOtp}
              >
                Next
              </button>
            ) : (
              <button
                className="mt-10 bg-blue-800 text-white px-8 py-2 rounded-lg"
                onClick={verifyOTP}
              >
                Verify OTP
              </button>
            )}
          </div>
        )}
        {formStep === 2 && (
          <div className="mb-3 flex flex-col mt-2  w-full items-center">
            <input
              className="form-control border-1 px-4 py-4 w-4/5 mt-5 rounded-lg border "
              type="text"
              name="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
            <input
              className="form-control border-1 px-4 py-4 w-4/5 mt-5 rounded-lg border "
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
            <input
              className="form-control border-1 px-4 py-4 w-4/5 mt-5 rounded-lg border "
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                // checked={btnEnable}
                className="mr-2  "
                // onChange={() => {
                //   setBtnEnable(!btnEnable);
                // }}
              />
              <p className="text-xs md:text-lg flex items-center font-semibold">
                I have read all the instructions and I agree to start new exam.
              </p>
            </div>
            <button
              className="mt-10 bg-blue-800 text-white px-8 py-2 rounded-lg"
              onClick={finalSubmit}
            >
              Register
            </button>
          </div>
        )}
      </div>
      {showError && (
        <div className="bg-red-500 rounded-lg w-80 py-4 px-4 mt-10 text-white font-normal text-center absolute bottom-40">
          {errorMessage}
        </div>
      )}
      {showConfetti && (
        <div className="bg-green-600 rounded-lg w-96 py-4 px-4 mt-10 text-white font-normal text-center">
          Congratulations, Your account is created.
        </div>
      )}
    </div>
  );
};
