import "./PrivacyPolicy.css";

import { Toolbar } from "../toolbar/toolbar";
import  Footer  from "../Footer/Footer";


const PrivacyPolicy = () => {
  return (
    <>

      <div className="">

        <Toolbar />

        <div className="">

          <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
            <p className="text-white text-4xl font-bold"> Privacy & Policy</p>
          </div>

          <div className=" flex justify-center bg-red-50 py-10">
            <div className="mx-10   px-5  ps-10 md:ps-0  md:w-[60%]">

              <ol className="" >
                <li className="my-5  ">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Information We Collect</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Personal Information: When you use our Website and App, we may
                      collect personal information that you voluntarily provide to us, such as
                      your name, email address, contact information, and other details you
                      choose to provide when registering or using our services.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Usage Information: We may also collect non-personal information
                      about your use of the Website and App, including your IP address,
                      browser type, operating system, device information, pages visited, and
                      actions taken.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Cookies and Similar Technologies: We may use cookies and similar
                      technologies to enhance your experience on the Website and App. Cookies
                      are small text files that are placed on your device to collect and store
                      certain information. You can control the use of cookies through your
                      browser settings.
                    </li>

                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Use of Information</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Providing Services: We may use the information we collect to provide
                      you with our services, process your requests, and communicate with you
                      regarding your account and any updates or changes to our services.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Improving User Experience: We may use the information to analyze
                      user behavior, track usage patterns, and improve the functionality and
                      content of our Website and App.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Marketing and Communications: With your consent, we may send you
                      promotional materials, updates, and newsletters related to our services.
                      You can opt-out of receiving such communications at any time.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Legal Compliance: We may use and disclose your information to comply
                      with applicable laws, regulations, legal processes, or enforceable
                      governmental requests.
                    </li>

                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Data Sharing and Disclosure</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Service Providers: We may share your information with trusted
                      third-party service providers who assist us in operating our Website and
                      App, providing services, or conducting business on our behalf. These
                      service providers are obligated to protect your information and can only
                      use it for the purposes specified by us.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Business Transfers: In the event of a merger, acquisition, or sale
                      of our business assets, your information may be transferred to the
                      acquiring entity or parties involved, subject to the same privacy
                      commitments as outlined in this policy.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Legal Requirements: We may disclose your information if required to
                      do so by law or in good faith belief that such action is necessary to
                      comply with legal obligations, protect our rights, or investigate fraud
                      or security issues.
                    </li>
                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Legal Compliance: We may use and disclose your information to comply
                      with applicable laws, regulations, legal processes, or enforceable
                      governmental requests.
                    </li>

                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Data Security</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      We employ industry-standard security measures to protect your
                      information from unauthorized access, disclosure, alteration, or
                      destruction. However, please note that no method of transmission over
                      the internet or electronic storage is completely secure, and we cannot
                      guarantee absolute security.
                    </li>


                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Third-Party Links</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      Our Website and App may contain links to third-party websites or
                      services. This Privacy Policy does not apply to those third-party
                      websites or services, and we are not responsible for their privacy
                      practices. We encourage you to review the privacy policies of those
                      third parties before providing any personal information.
                    </li>


                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Changes to this Privacy Policy</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      We do not knowingly collect personal information from children under the
                      age of 13. Our app is not intended for use by children under 13 years
                      old. If you are a parent or guardian and believe that your child has
                      provided us with personal information, please contact us immediately so
                      that we can take appropriate action to remove such information from our
                      records.We reserve the right to modify or update this Privacy Policy from time
                      to time. Any changes will be effective upon posting the revised policy
                      on our Website or App. We encourage you to review this Privacy Policy
                      periodically to stay informed about our data practices.
                    </li>


                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Children Policy</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      We reserve the right to modify or update this Privacy Policy from time
                      to time. Any changes will be effective upon posting the revised policy
                      on our Website or App. We encourage you to review this Privacy Policy
                      periodically to stay informed about our data practices.
                    </li>


                  </ol>

                </li>

                <li className="">

                  <p className="text-xl md:text-4xl font-bold  my-3 text-gray-700">Contact Us</p>


                  <ol className="px-5  mx-3 list-decimal text-base" >

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      If you have any questions, concerns, or requests regarding this Privacy
                      Policy, please contact us at support@reproneetlearning.com.
                    </li>

                    <li className="my-2  ms-2 px-2 md:my-5 md:text-xl">
                      By using our Website and App, you acknowledge that you have read,
                      understood, and agreed to this Privacy Policy.
                    </li>


                  </ol>

                </li>

              </ol>

            </div>
          </div>

        </div>

        <Footer/>

      </div>

     
    </>
  );
};

export { PrivacyPolicy };
