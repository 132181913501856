import {
  applyMiddleware,
  configureStore,
  compose,
  createStore,
} from "@reduxjs/toolkit";
import appReducer from "./AppStateReducer";
import thunk from "redux-thunk";
import courseReducer from "./courseSlice";
import userReducer from "./loginSlice";
import enrollmentReducer from "./enrolledCourse";
import enrollmentCourseDetailReducer from "./courseDetailSlice";
import questionReducer from "./QuestionReducer";
import examAttemptReducer from "./ExamAttemptReducer";
import { TestSeriesReducer } from "./reducer/test_series_reducer/TestSeriesReducer";
import { ApiStateReducer } from "./ApiStateReducer";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(
  {
    reducer: {
      courseSlice: courseReducer,
      courseDetail: enrollmentCourseDetailReducer,
      enrolledSlice: enrollmentReducer,
      userSlice: userReducer,
      questionReducer: questionReducer,
      appState: appReducer,
      examAttemptReducer: examAttemptReducer,
      testSeriesReducer: TestSeriesReducer,
      apiStateReducer: ApiStateReducer,
    },
  },
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
