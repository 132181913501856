import ReactPlayer from "react-player";
import { Toolbar } from "../toolbar/toolbar";

const TestimonialComponent = () => {
  const testimonial = [
    {
      candidateName: "Dr. Sunita Naik",
      rank: 5,
      course: "NEET SS 2021",
      url: "https://www.youtube.com/watch?v=-Dvnd9rT8_Y",
    },
    {
      candidateName: "Dr. Navin",
      rank: 6,
      course: "NEET SS 2021",
      url: "https://www.youtube.com/watch?v=O33E-IT--gc",
    },
    {
      candidateName: "Dr. Suvidya",
      rank: 1,
      course: "INISSET",
      url: "https://youtu.be/NWkdrdXwol0",
    },

    {
      candidateName: "Dr. Asha",
      rank: 6,
      course: "NEET SS 2022",
      url: "https://youtu.be/MgYOk8ezRgI",
    },

    {
      candidateName: "Dr. Nayana Hiremath",
      rank: 9,
      course: "FET 2021",
      url: "https://www.youtube.com/watch?v=IKY82zIstOc",
    },
    {
      candidateName: "Dr. Mamidi Durga Sai Vathsalya",
      rank: 5,
      course: "NEET SS 2021",
      url: "https://youtu.be/EdF27YSDdbk",
    },
    {
      candidateName: "Dr. Parvathy",
      rank: 7,
      course: "NEET SS 2021",
      url: "https://www.youtube.com/watch?v=NZbK7OWzNe8",
    },
    {
      candidateName: "Dr. Tushar Mahapatra",
      rank: 14,
      course: "NEET SS 2021",
      url: "https://youtu.be/2ZC9MPTmeUI",
    },
    {
      candidateName: "Dr. Pooja Chatterjee",
      rank: 5,
      course: "NEET SS 2021",
      url: "https://youtu.be/qWgFvxTIZcs",
    },

    {
      candidateName: "Dr. Shikha Marwah Pal",
      rank: 28,
      course: "FET 2021",
      url: "https://www.youtube.com/watch?v=uowUPUGp7sY",
    },

    {
      candidateName: "Dr. Shilpa Sood",
      rank: 18,
      course: "NEET SS 2021",
      url: "https://www.youtube.com/watch?v=x39eIZg-Gp0",
    },

    {
      candidateName: "Dr. Sunidhi Minhas",
      rank: 22,
      course: "FET 2021",
      url: "https://www.youtube.com/watch?v=s42l4znRQR4",
    },
    {
      candidateName: "Dr. Haripriya Kakumanu",
      rank: 17,
      course: "FET 2021",
      url: "https://www.youtube.com/watch?v=gxfDHVJGiW0",
    },

    {
      candidateName: "Dr. ESHA",
      rank: 1,
      course: "JIPMER",
      url: "https://www.youtube.com/watch?v=daOYPw550FQ",
    },
    {
      candidateName: "Dr. Divya Jain",
      rank: 44,
      course: "JIPMER",
      url: "https://www.youtube.com/watch?v=arBpkp7l-n8",
    },

    {
      candidateName: "Dr. Devyani Mukherjee,",
      rank: 35,
      course: "FET 2021",
      url: "https://www.youtube.com/watch?v=mEcC3r52iKc",
    },

    {
      candidateName: "Dr. Bharathi Priya,",
      rank: 15,
      course: "FET 2021",
      url: "https://www.youtube.com/watch?v=uNunchkOT6A",
    },
    {
      candidateName: "Dr Juhi Khithani",
      rank: 29,
      course: "NEET SS 2022",
      url: "https://www.youtube.com/watch?v=FKOQwk6kU00",
    },

    {
      candidateName: "Dr. Swathi Sarma",
      rank: 39,
      course: "NEET SS 2022",
      url: "https://www.youtube.com/watch?v=9tlROArN2Yg",
    },
    {
      candidateName: "Dr. Analise",
      rank: 38,
      course: "NEET SS 2022",
      url: "https://www.youtube.com/watch?v=u59xJhVZKTM",
    },
    {
      candidateName: "Dr. Bhawna",
      rank: 2,
      course: "INISSET",
      url: "https://www.youtube.com/watch?v=Bsy2qZMA2KU",
    },

    {
      candidateName: "Dr. Suvidya Singh",
      rank: 17,
      course: "NEET SS 2022",
      url: "https://www.youtube.com/watch?v=cHFO9UPDSy8",
    },

    {
      candidateName: "Dr. Radha Gupta",
      rank: 28,
      course: "NEET SS 2022",
      url: "https://www.youtube.com/watch?v=mHDKUJWcBV8",
    },

    {
      candidateName: "Dr. Pragati Tripathi",
      rank: 31,
      course: "NEET SS 2022",
      url: "https://www.youtube.com/watch?v=VHh-2s8hjNE",
    },
  ];

  return (
    <div className="top-0">
      <Toolbar />

      <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
        <p className="text-white text-5xl font-bold"> Testimonials</p>
      </div>

      <div className="flex flex-wrap w-90 self-center justify-center mt-20">
        {testimonial.map((item) => {
          return (
            <div className="w-[320px] h-[330px] bg-white shadow-sm drop-shadow-lg rounded-md mx-10 my-10 ">
              <ReactPlayer url={item.url} width={320} height={200} />
              <div className="flex flex-col px-5 py-5">
                <p className="text-gray-900 font-medium">
                  {item.candidateName}
                </p>
                <p>
                  Rank {item.rank}, {item.course}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};



export default TestimonialComponent;
