import React, { Component, useEffect } from "react";
import store from "../redux/store";
import { LoginThunk } from "../redux/loginSlice";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { colors } from "@mui/material";
import { socket } from "../App";
import { brown } from "@mui/material/colors";
export const LoginScreen = () => {
  // Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || typeof safari !== "undefined");

  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 71
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Blink engine detection

  const navigator = useNavigate();
  const { userInfo, loading, error } = useSelector((state) => state.userSlice);
  if (userInfo.userId != null) {
    var browser = "Unknown";
    if (isChrome) {
      browser = "Chrome";
    } else if (isEdge) {
      browser = "Edge";
    } else if (isFirefox) {
      browser = "firefox";
    } else if (isSafari) {
      browser = "safari";
    }

    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    navigator("/classroom");
  }

  const handleLogin = (e) => {
    e.preventDefault();

    const { email, password } = e.target;
    if (email.value === null || email.value === undefined) {
      alert("Email is required");
      return;
    }
    if (password.value === null || password.value === undefined) {
      alert("Email is required");
      return;
    }

    // alert(`email : ${email.value}` + `\n password:${password.value} `);
    store.dispatch(
      LoginThunk({
        email: email?.value?.trim(),
        password: password?.value?.trim(),
        deviceType: "WEB",
      })
    );
  };

  return loading === true ? (
    <div>Loading</div>
  ) : (
    <div className="w-full flex justify-center flex-col content-center mt-[10%] max-sm:mt-[40%] ">
      <div className="flex w-3/5 h-[500px] rounded-lg right-0 left-0 border-2 border-neutral-100 max-sm:w-5/6 max-sm:h-[400px] self-center  ">
        <div className="w-[55%] bg-login-img bg-contain bg-no-repeat max-sm:hidden " />
        <div className="w-[50%]  h-full flex self-center bg-red-50 max-sm:w-full max-sm:bg-orange-50">
          <form
            onSubmit={handleLogin}
            className="w-full flex flex-col items-center justify-center"
          >
            <p className="text-2xl font-medium text-[#0e0e0e] max-sm:text-xl">
              Signin
            </p>
            <div className="mb-3 flex flex-col mt-10  w-full items-center">
              <input
                className="form-control border-1 px-4 py-4 w-4/5 rounded-lg border"
                name="email"
                type="email"
                placeholder="Enter email"
              />
            </div>
            <div className="mb-3 flex flex-col mt-2  w-full items-center">
              <input
                className="form-control border-1 px-4 py-4 w-4/5 rounded-lg border "
                type="password"
                name="password"
                placeholder="Enter password"
              />
            </div>
            <div className="custom-control custom-checkbox flex">
              <input
                style={{
                  border: 0,
                }}
                type="checkbox"
                className="form-control border-1 px-2 rounded-sm"
                id="customCheck1"
              />
              <label
                className="custom-control-label px-4"
                htmlFor="customCheck1"
              >
                Remember me
              </label>
            </div>
            <button
              type="submit"
              className="bg-black self-center text-white rounded-lg w-[150px] h-[35px] mb-5 mt-5 hover: ease-in 300 "
            >
              Login
            </button>
            <p className="forgot-password text-right">Forgot password?</p>
            <p className="forgot-password text-right mt-4 max-md:mt-2 font-semibold cursor-pointer hover:text-blue-700">
              <Link to="/register" className="mx-0">
                Create New Account
              </Link>
            </p>
          </form>
        </div>
      </div>
      {error !== "" ? (
        <p className="bg-red-100 w-80 mt-10 self-center px-4 py-4 rounded-lg text-sm font-medium text-center">
          {error}
        </p>
      ) : null}
    </div>
  );
};
