import { Constant } from "../constant/constant";
import { ActionType } from "./action/ActionType";

const initialState = {
  showLoader: false,
  errorMessage: "",
  showSnackBar: false,
  snackBarMessage: "",
  snackBarSeverity: Constant.SEVERITY_SUCCESS,
  apiError: false
};

export const ApiStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.SHOW_LOADER:
      return {
        ...state,
        showLoader: action.payload.showLoader,
      };
    case ActionType.ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    case ActionType.SHOW_SNACKBAR:
      return {
        ...state,
        showSnackBar: action.payload.showSnackBar,
        snackBarMessage: action.payload.snackBarMessage,
        snackBarSeverity: action.payload.snackBarSeverity,
        apiError: action.payload.apiError
      };
    default:
      return state;
  }
};
